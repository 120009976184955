import { Autocomplete, TextField } from "@mui/material"

interface SearchSelectProps<T> {
  options: T[],
  label: string,
  helperText?: string,
  required?: boolean,
  labelPresentation: (option: T) => string,
  onChange: (event: React.SyntheticEvent<Element, Event>, newInputValue: T | null) => void,
  onInputChange: (event: React.SyntheticEvent<Element, Event>, newInputValue: string) => void,
}

const SearchSelect = <T,>({ options, label, helperText, required, labelPresentation, onChange, onInputChange }: SearchSelectProps<T>) => {
  return (
    <>
      <Autocomplete
        disablePortal
        id="searchable-combo-box"
        size="small"
        fullWidth
        autoComplete
        includeInputInList
        filterSelectedOptions
        filterOptions={(x) => x}
        options={options}
        getOptionLabel={labelPresentation}
        noOptionsText="Nessun dato disponibile"
        onChange={onChange}
        onInputChange={onInputChange}
        renderInput={(params: any) => <TextField required={required || false} helperText={helperText || ""} fullWidth {...params} label={label} />}
      />
    </>
  )
}

export default SearchSelect
