import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Typography } from '@mui/material';

export interface SearchElementType {
  id?: string
  name: string
  newName?: string
}

const filter = createFilterOptions({
  matchFrom: 'start',
  stringify: (option: SearchElementType) => option.name,
});

interface DynamicSearchSelectProps<T> {
  label: string,
  required: boolean,
  options: SearchElementType[],
  selectedOptionId: string | null,
  helperText?: string,
  loading: boolean,
  onChange: (event: React.SyntheticEvent<Element, Event>, newInputValue: SearchElementType | null) => void,
  onInputChange: (event: React.SyntheticEvent<Element, Event>, newInputValue: string) => void,
  onNewElementAdded: (newElement: SearchElementType) => void,
}

const DynamicSearchSelect = <T,>({
  label,
  required,
  options,
  selectedOptionId,
  helperText,
  loading,
  onChange,
  onInputChange,
  onNewElementAdded
}: DynamicSearchSelectProps<T>) => {

  const [value, setValue] = React.useState<SearchElementType | null>(options.filter(op => op.id == selectedOptionId)[0]);

  return (
    <>
      <Autocomplete
        value={value}
        options={options}
        disablePortal
        size="small"
        loading={loading}
        loadingText={
          <Typography>Caricamento ...</Typography>
        }
        fullWidth
        onChange={(event, newValue) => {
          if (!newValue) {
            setValue(null)
          } else if (newValue && newValue.id) {
            setValue(newValue);
            onChange(event, newValue)
          } else {
            if (newValue && newValue?.newName) {
              let newElement = {
                name: newValue?.newName,
              }
              setValue(newElement);
              onNewElementAdded(newElement)
            }
          }
          // setValue(newValue);
        }}
        onInputChange={onInputChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;

          const isExisting = options.some((option) => inputValue === option.name);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              name: `Aggiungi "${inputValue}"`,
              newName: inputValue
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="dynamic-searchable-combo-box"
        noOptionsText="Nessun dato disponibile"
        getOptionLabel={(option) => {
          if (option.newName) {
            return option?.newName
          }
          return option.name
        }}
        renderOption={(props, option) => {
          const { ...optionProps } = props;
          return (
            <li key={option.id} {...optionProps}>
              {option.name}
            </li>
          );
        }}
        renderInput={
          (params: any) =>
            <TextField
              required={required || false}
              // error={required && !value}
              helperText={helperText || ""}
              fullWidth
              {...params}
              label={label}
            />
        }
      />
    </>
  );
}

export default DynamicSearchSelect
