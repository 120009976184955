import { Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from "@mui/lab"
import { StoricoStatoContratto } from "../../models/ContrattoModel"
import { displayFormattedWithTime } from "../../utils/date"

interface ContractStateHistoryProps {
  history: StoricoStatoContratto[]
}

const ContractStateHistory: React.FC<ContractStateHistoryProps> = ({ history }) => {

  const titleFontSize = "15px"

  return (
    <>
      <Timeline style={{ transition: "all .2s", }}>
        <TimelineItem>
          <TimelineOppositeContent fontSize={titleFontSize} color="text.secondary" sx={{ fontWeight: 'bold' }}>
            Data di Cambio Stato
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot variant="outlined" />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent fontSize={titleFontSize} sx={{ fontWeight: 'bold' }}>Stato Contratto</TimelineContent>
        </TimelineItem>
        {history.map(he =>
          <TimelineItem key={he.id}>
            <TimelineOppositeContent color="text.secondary">
              {displayFormattedWithTime(he.data)}
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>{he.statocontratto.nome}</TimelineContent>
          </TimelineItem>
        )}
      </Timeline>
    </>
  )
}

export default ContractStateHistory
