import React, { useState } from "react"
import SideBar from "../../components/sidebar/Sidebar"
import { useQuery } from "react-query"
import { Grid, Skeleton } from "@mui/material"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { fetchBene } from "../../api/beni"
import { BeneModelDetailed } from "../../models/BeneModel"
import BeneDetailComponent from "../../components/bene/BeneDetailComponent"
import { fetchDetailedBeneQuery } from "../../utils/QueryClient"

const BeneDetailPage: React.FC<{}> = () => {

  const navigator = useNavigate()
  const { id } = useParams()
  const [bene, setBene] = useState<BeneModelDetailed>(undefined as any)

  if (id === undefined) navigator("/bene", { replace: true })
  if (id === undefined) navigator("/bene", { replace: true })

  const query = useQuery({
    queryKey: [fetchDetailedBeneQuery],
    queryFn: () => {
      return fetchBene({ data: id })
    },
    onSuccess: (getResponse) => {
      setBene(getResponse.data)
    }, refetchOnWindowFocus: false
  })

  if (id === undefined) return (<Navigate to="/bene" replace />)

  if (query.isError) return (<Navigate to="/" replace />)

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        {
          (query.isFetching || query.isLoading || query.isRefetching) ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Grid>
            </Grid>
          ) : <BeneDetailComponent bene={bene} />
        }
      </div>
    </div>
  )
}

export default BeneDetailPage
