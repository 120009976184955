import React, { useState } from "react"
import SideBar from "../../components/sidebar/Sidebar"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { Grid, Skeleton } from "@mui/material"
import { useQuery } from "react-query"
import { fetchContrattoQueryKey } from "../../utils/QueryClient"
import { fetchContratto } from "../../api/contratto"
import { ContrattoDetailed } from "../../models/ContrattoModel"
import "./Contratto.scss"
import InfoContrattoComponent from "../../components/contratto/InfoContrattoComponent"

const ContrattoDetailPage: React.FC<{}> = () => {

  const navigator = useNavigate()
  const { id } = useParams()
  const [contratto, setContratto] = useState<ContrattoDetailed>(undefined as any)

  if (id === undefined) navigator("/contratto", { replace: true })

  const query = useQuery({
    queryKey: [fetchContrattoQueryKey],
    queryFn: () => fetchContratto({ data: id }),
    onSuccess: (getResponse) => {
      setContratto(getResponse.data)
    }, refetchOnWindowFocus: false
  })

  if (query.isError) return (<Navigate to="/" replace />)

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        {
          (query.isFetching || query.isLoading || query.isRefetching) ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Grid>
            </Grid>
          ) : (
            <>
              <InfoContrattoComponent contratto={contratto} />
            </>
          )
        }
      </div>
    </div>
  )
}

export default ContrattoDetailPage
