import React, { useState } from "react"
import SideBar from "../../components/sidebar/Sidebar"
import "./CustomerDetails.scss"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { Button, Grid, Skeleton } from "@mui/material"
import { useQuery } from "react-query"
import { WithAnagraficaClienteID } from "../../models/CommonModels"
import { PersonaGiuridicaModel } from "../../models/PersonaGiuridicaModel"
import { FetchPG } from "../../api/personagiuridica"
import { fetchPGQueryKey } from "../../utils/QueryClient"
import CustomerCredenzialiPortaleVenditaTable from "../../components/customer/CustomerCredenzialiPortaleVenditaTable"
import CustomerEmailsTable from "../../components/customer/CustomerEmailsTable"
import CustomerIndirizziTable from "../../components/customer/CustomerIndirizziTable"
import CustomerTelefoniTable from "../../components/customer/CustomerTelefoniTable"
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork"
import AddIcon from "@mui/icons-material/Add"
import BeneTableComponent from "../../components/bene/BeneTableComponent"
import PersonaGiuridicaDetailComponent from "../../components/customer/pg/PersonaGiuridicaDetailComponent"
import { CredenzialiPortaleVenditaModel } from "../../models/CredenzialiPortaleVenditaModel"
import { Indirizzo } from "../../models/IndirizzoModel"

interface WithPG extends WithAnagraficaClienteID {
  pgcorrelata: string
}

const PersonaGiuridicaDetailPage: React.FC<{}> = () => {
  const navigator = useNavigate()
  const [dettagliAggiuntivi, setDettagliAggiuntivi] = useState(false)
  const [personaGiuridica, setPersonaGiuridica] = useState<PersonaGiuridicaModel>(undefined as any)
  const { idpefidana } = useParams()
  if (idpefidana === undefined || idpefidana.split("-").length !== 2) navigator("/personafisica", { replace: true })

  const query = useQuery({
    queryKey: [fetchPGQueryKey, idpefidana],
    queryFn: () => FetchPG({ data: idpefidana!!.split("-")[0] }),
    onSuccess: (getResponse) => {
      setPersonaGiuridica(getResponse.data)
    }, refetchOnWindowFocus: false
  })

  if (query.isError) return (<Navigate to="/" replace />)

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        {
          (query.isFetching || query.isLoading || query.isRefetching) ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Grid>
            </Grid>
          ) : <PersonaGiuridicaDetailComponent pg={personaGiuridica} />
        }
        <Button sx={{ marginTop: "10px" }} color="primary" onClick={() => setDettagliAggiuntivi(!dettagliAggiuntivi)} startIcon={(<AddHomeWorkIcon />)}>
          {!dettagliAggiuntivi ? "Visualizza Dettagli Aggiuntivi" : "Nascondi Dettagli Aggiuntivi"}
        </Button>
        {dettagliAggiuntivi && (
          <EntitaComuni anagrafica_cliente_id={+(idpefidana?.split("-")[1] || "-1")} />
        )}
        {
          (query.isFetching || query.isLoading || query.isRefetching) ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Grid>
            </Grid>
          ) : (<>
            <Button
              sx={{ marginTop: "10px" }}
              color="primary"
              onClick={() => {
                navigator(
                  "/contratto/crea/".concat(
                    idpefidana!!,
                    "___PG___",
                    personaGiuridica.ragione_sociale
                  )
                );
              }}
              startIcon={<AddIcon />}
            >
              Crea un nuovo Contratto
            </Button>
            <GestioneBeni
              pgcorrelata={idpefidana!!.concat("___PG___", personaGiuridica.ragione_sociale)}
              anagrafica_cliente_id={+(idpefidana?.split("-")[1] || "-1")} />
          </>)
        }
      </div>
    </div>
  )
}

const EntitaComuni: React.FC<WithAnagraficaClienteID> = ({ anagrafica_cliente_id }) => {

  return (
    <Grid sx={{ marginTop: "10px" }} spacing={2} container>
      <Grid className="info_Table" item xs={8}><CustomerCredenzialiPortaleVenditaTable anagrafica_cliente_id={anagrafica_cliente_id} record={{} as CredenzialiPortaleVenditaModel} createFormOpen={false} toggleCreateFormOpen={function (): void {
        throw new Error("Function not implemented.")
      }} setEnableCreateButton={function (state: boolean): void {
        throw new Error("Function not implemented.")
      }} /></Grid>
      <Grid className="info_Table" item xs={4}><CustomerEmailsTable anagrafica_cliente_id={anagrafica_cliente_id} toggleCreateFormOpen={function (): void {
        throw new Error("Function not implemented.")
      }} createFormOpen={false} /></Grid>
      <Grid className="info_Table" item xs={8}><CustomerIndirizziTable anagrafica_cliente_id={anagrafica_cliente_id} record={{} as Indirizzo} createFormOpen={false} toggleCreateFormOpen={function (): void {
        throw new Error("Function not implemented.")
      }} /></Grid>
      <Grid className="info_Table" item xs={4}><CustomerTelefoniTable anagrafica_cliente_id={anagrafica_cliente_id} createFormOpen={false} toggleCreateFormOpen={function (): void {
        throw new Error("Function not implemented.")
      }} /></Grid>
    </Grid>
  )
}

const GestioneBeni: React.FC<WithPG> = ({ pgcorrelata, anagrafica_cliente_id }) => {

  const navigator = useNavigate()

  return (
    <>
      <Button sx={{ marginTop: "10px" }} color="primary"
        onClick={() => {
          console.log("/bene/crea/".concat(pgcorrelata))
          navigator("/bene/crea/".concat(pgcorrelata))
        }} startIcon={(<AddIcon />)}>
        Crea un nuovo Articolo
      </Button>
      <BeneTableComponent enableCreate={false} alterFabVisibility={() => { }} fabVisible={false} dettaglio={false} anagrafica_cliente_id={anagrafica_cliente_id} />
    </>
  )
}

export default PersonaGiuridicaDetailPage
