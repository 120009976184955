import React, { useState } from "react";
import "./CustomerPage.scss"
import AddIcon from "@mui/icons-material/Add";
import { Fab } from "@mui/material";
import PersonaFisicaTableComponent from "../../components/customer/pef/PersonaFisicaTableComponent";

const PersonaFisicaPage: React.FC<{}> = () => {

  const [fabVisible, setFabVisible] = useState(true)

  return (
    <>
      {fabVisible &&
        <Fab
          onClick={() => setFabVisible(false)}
          sx={{ position: "fixed", bottom: 16, right: 16 }} color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      }
      <PersonaFisicaTableComponent alterFabVisibility={setFabVisible} fabVisible={fabVisible} />
    </>
  )
}

export default PersonaFisicaPage;
