import { TextField, Button, DialogTitle, DialogContent, Dialog, DialogActions, Typography, Box } from "@mui/material"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import dayjs, { Dayjs } from "dayjs"
import { useState } from "react"
import { WithRecordID, WithCUToggleCommandsAndData, WithData } from "../../../models/CommonModels"
import { PFPost, PFPut, PersonaFisicaModel, PersonaFisicaPostPutModel } from "../../../models/PersonaFisicaModel"
import { AnagraficaClientePostModel, GenesiAnagraficaCliente } from "../../../models/AnagraficaClienteModel"
import { useMutation, useQueryClient } from "react-query"
import { createPF, editPF } from "../../../api/personafisica"
import { fetchPFQueryKey, fetchPFsQueryKey } from "../../../utils/QueryClient"
import { calcolaDataDiNascita } from "../../../utils/date"
import { REQUIRED_HELPER_TEXT } from "../../../utils/form"
import "dayjs/locale/it";
import { AxiosError } from "axios"
import { fromAxiosErrorToMessage } from "../../../utils/crud"
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent"

const PersonaFisicaFormComponent: React.FC<WithCUToggleCommandsAndData<PersonaFisicaModel>> = ({ initialData, open, toggleOpen, editEnabled, onClose }) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [nome, setNome] = useState<string>(initialData.nome)
  const [cognome, setCognome] = useState<string>(initialData.cognome)
  const [codiceFiscale, setCodiceFiscale] = useState<string>(initialData.codice_fiscale)
  const [dataNascita, setDataNascita] = useState<Dayjs | undefined>(dayjs(initialData.data_di_nascita?.replace("/", "-")))
  const [genesis, setGenesis] = useState<string>(initialData.anagraficacliente?.genesi_cliente || "")

  const resetFields = () => {
    // resetting fields
    setNome("")
    setCognome("")
    setCodiceFiscale("")
    setDataNascita(undefined)
    setGenesis("")
  }

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createPF(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchPFsQueryKey)
      if (onClose !== undefined)
        onClose()
      resetFields()
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editPF(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchPFsQueryKey)
      queryClient.invalidateQueries(fetchPFQueryKey)
      toggleOpen()
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchPFsQueryKey)
      queryClient.invalidateQueries(fetchPFQueryKey)
    }
  }

  const handleSubmit = () => {
    if (editEnabled) {

      if (initialData === undefined) {
        return
      }

      const pfPost = {} as PFPut
      pfPost.anagraficacliente = {} as GenesiAnagraficaCliente

      pfPost.anagraficacliente.genesi_cliente = genesis

      pfPost.personafisica = {} as PersonaFisicaPostPutModel
      pfPost.personafisica.codice_fiscale = codiceFiscale
      pfPost.personafisica.nome = nome
      pfPost.personafisica.cognome = cognome
      pfPost.personafisica.data_di_nascita = dataNascita?.format("YYYY/MM/DD") || dayjs().toString()

      const postData = {} as WithRecordID
      postData.recordID = initialData?.id
      postData.data = pfPost

      editMutation.mutate(postData)
    } else {
      const pfPost = {} as PFPost
      pfPost.anagraficacliente = {} as AnagraficaClientePostModel

      pfPost.anagraficacliente.genesi_cliente = genesis
      pfPost.anagraficacliente.tipologia_cliente = "PEF"

      pfPost.personafisica = {} as PersonaFisicaPostPutModel
      pfPost.personafisica.codice_fiscale = codiceFiscale
      pfPost.personafisica.nome = nome
      pfPost.personafisica.cognome = cognome
      pfPost.personafisica.data_di_nascita = dataNascita?.format("YYYY/MM/DD") || dayjs().toString()

      pfPost.email = []
      pfPost.indirizzi = []
      pfPost.telefoni = []

      const postData = {} as WithData
      postData.data = pfPost

      createMutation.mutate(postData)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>{editEnabled ? "Modifica" : "Crea Nuovo"} Cliente</DialogTitle>
        <DialogContent style={{ width: 500 }}>

          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => { setHideError(true) }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>{REQUIRED_HELPER_TEXT}</Typography>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Nome"
            name="nome"
            size="small"
            value={nome}
            onChange={e => setNome(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Cognome"
            name="cognome"
            size="small"
            value={cognome}
            onChange={e => setCognome(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Codice Fiscale"
            name="codice_fiscale"
            size="small"
            value={codiceFiscale}
            onChange={e => setCodiceFiscale(e.target.value)}
          />
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                format="DD/MM/YYYY"
                name="data_di_nascita"
                slotProps={{ textField: { size: "small", fullWidth: true } }}
                value={dataNascita}
                onChange={(newValue) => setDataNascita(dayjs(newValue))}
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button onClick={() => {
            setDataNascita(dayjs(calcolaDataDiNascita(codiceFiscale)))
          }}>
            Calcola data di nascita
          </Button>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Genesi Cliente"
            name="genesi_cliente"
            size="small"
            value={genesis}
            onChange={e => setGenesis(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ paddingLeft: "18pt", paddingRight: "18pt", justifyContent: "space-between" }}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button onClick={() => {
              if (onClose !== undefined) {
                onClose()
              }

              if (!editEnabled) {
                resetFields()
              }

              closeActions()
            }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">{editEnabled ? "Salva" : "Crea"}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PersonaFisicaFormComponent
