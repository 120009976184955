import React, { useState } from "react"
import SideBar from "../../components/sidebar/Sidebar"
import "./CustomerDetails.scss"
import { useNavigate, useParams } from "react-router-dom"
import { Grid, Skeleton } from "@mui/material"
import { FetchPF } from "../../api/personafisica"
import { useQuery } from "react-query"
import { PersonaFisicaModel } from "../../models/PersonaFisicaModel"
import { fetchPFQueryKey } from "../../utils/QueryClient"
import PersonaFisicaDetailComponent from "../../components/customer/pef/PersonaFisicaDetailComponent"

const PersonaFisicaDetailPage: React.FC<{}> = () => {

  const navigator = useNavigate();

  const [personaFisica, setPersonafisica] = useState<PersonaFisicaModel>(
    undefined as any
  )

  const { idpefidana } = useParams();
  if (idpefidana === undefined || idpefidana.split("-").length !== 2)
    navigator("/personafisica", { replace: true })

  const query = useQuery({
    queryKey: [fetchPFQueryKey, idpefidana],
    queryFn: () => FetchPF({ data: idpefidana!!.split("-")[0] }),
    onSuccess: (getResponse) => {
      setPersonafisica(getResponse.data);
    },
    refetchOnWindowFocus: false,
  })

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        {query.isFetching || query.isLoading || query.isRefetching ? (
          <Grid container>
            <Grid item xs={12}>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Grid>
          </Grid>
        ) : (
          <PersonaFisicaDetailComponent pef={personaFisica} />
        )}
      </div>
    </div>
  )
}

export default PersonaFisicaDetailPage
