import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { Skeleton } from "@mui/material"
import "./CustomerDetails.scss"
import { WithData, WithAnagraficaClienteID } from "../../models/CommonModels"
import { Telefono } from "../../models/TelefonoModel"
import { deleteTelefono, fetchCustomerTelefoni } from "../../api/telefoni"
import { fetchTelefoniQueryKey } from "../../utils/QueryClient"
import { GridRenderCellParams } from "@mui/x-data-grid"
import CustomerTelefonoForm from "./extra/CustomerTelefonoForm"
import DeleteDialog from "../shared/DeleteDialog"
import SimpleDataTable from "../shared/SimpleDataTable"

interface WithTelefonoRecord extends WithAnagraficaClienteID {
  createFormOpen: boolean,
  toggleCreateFormOpen: () => void
}

const CustomerTelefoniTable: React.FC<WithTelefonoRecord> = ({ anagrafica_cliente_id, createFormOpen, toggleCreateFormOpen }) => {

  const queryClient = useQueryClient()

  const [telefoni, setTelefoni] = useState<Telefono[]>(undefined as any)
  const [selectedRowElement, setSelectedRowElement] = useState<Telefono>()

  const [telefonoDataEditFormOpen, setTelefonoDataEditFormOpen] = useState<boolean>(false)
  const toggleTelefonoDataEditFormOpen = () => setTelefonoDataEditFormOpen(!telefonoDataEditFormOpen)

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen)

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteTelefono(x),
    onSuccess: () => {
      setSelectedRowElement(undefined)
      queryClient.invalidateQueries(fetchTelefoniQueryKey)
    },
    onError: () => { }
  })

  const query = useQuery({
    queryKey: [fetchTelefoniQueryKey],
    queryFn: () => fetchCustomerTelefoni(anagrafica_cliente_id),
    onSuccess: (getResponse) => { setTelefoni(getResponse.data) }, refetchOnWindowFocus: false
  })

  if (query.isFetching || query.isLoading)
    return (<Skeleton animation="wave" />)

  // TODO: verify if (query.isError) return (<Navigate to="/personafisica" replace />)

  const editOperations = (record: GridRenderCellParams<any, Telefono>) => {
    setSelectedRowElement(record.value)
    toggleTelefonoDataEditFormOpen()
  }

  const deleteOperations = (record: GridRenderCellParams<any, Telefono>) => {
    setSelectedRowElement(record.value)
    toggleDeleteAlert()
  }

  return (
    <>
      <SimpleDataTable<Telefono>
        columns={[
          {
            field: "numero",
            headerName: "Numero",
            flex: 1,
          },
        ]}
        rows={
          (telefoni !== undefined) ? telefoni.map(d => {
            return {
              id: d.id,
              numero: d.numero,
              actions: d,
              delete_action: d
            } as unknown as Telefono
          }) : []
        }
        rowCount={telefoni.length}
        editOperations={editOperations}
        deleteOperations={deleteOperations}
      />

      {/* Add Telefono */}
      <CustomerTelefonoForm
        initialData={{ id_cliente: anagrafica_cliente_id } as Telefono}
        open={createFormOpen}
        toggleOpen={toggleCreateFormOpen}
      />

      {/* Edit Telefono */}
      {selectedRowElement && <CustomerTelefonoForm
        initialData={selectedRowElement}
        open={telefonoDataEditFormOpen}
        toggleOpen={toggleTelefonoDataEditFormOpen}
        editEnabled={true}
      />}

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.numero}
        onPositive={
          () => {
            toggleDeleteAlert()
            const x = {} as WithData
            x.data = selectedRowElement?.id
            deleteMutation.mutate(x)
          }
        }
        onNegative={toggleDeleteAlert}
      />
    </>
  )
}

export default CustomerTelefoniTable
