import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, TextField, Typography } from "@mui/material"
import { WithCUToggleCommandsAndData, WithRecordID, WithData } from "../../models/CommonModels"
import { ContrattoPostPutModel, ContrattoRequest, ContrattoTableModel } from "../../models/ContrattoModel"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { createContratto, editContratto } from "../../api/contratto"
import { fetchPFsQByKeyQueryKey, fetchCommissioniQueryKey, fetchStatoContrattoSelectQueryKey, fetchTipologiaContrattoSelectQueryKey, fetchContrattoQueryKey } from "../../utils/QueryClient"
import { REQUIRED_HELPER_TEXT } from "../../utils/form"
import dayjs, { Dayjs } from "dayjs"
import { NomeDescrizione } from "../../models/StatoTipologiaModel"
import { PersonaFisicaModel } from "../../models/PersonaFisicaModel"
import SearchSelect from "../shared/SearchSelect"
import { fetchPFsBySearchKey } from "../../api/personafisica"
import { useDebounce } from "@uidotdev/usehooks"
import StatoTipologiaSelectComponent from "../shared/StatoTipologiaSelectComponent"
import { fetchNomeDescrizioneForEntity, statoContrattoUri, tipologiaContrattoUri } from "../../api/statotipologia"
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import "dayjs/locale/it"
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent"
import { AxiosError } from "axios"
import { fromAxiosErrorToMessage } from "../../utils/crud"

const ContrattoFormComponent: React.FC<WithCUToggleCommandsAndData<ContrattoTableModel>> = ({ editEnabled, onClose, initialData, open, toggleOpen }) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [clienti, setClienti] = useState<PersonaFisicaModel[]>([])
  const [searchClienti, setSearchClienti] = useState<string>()
  const deferredSearchClienti = useDebounce(searchClienti, 1000);
  const [selectedClienteId, setSelectedClienteId] = useState<number | undefined>(initialData.id_cliente)

  const [statoContrattoRecords, setStatoContrattoRecords] = useState<NomeDescrizione[]>([])
  const [tipologiaContrattoRecords, setTipologiaContrattoRecords] = useState<NomeDescrizione[]>([])

  const [note, setNote] = useState(initialData.note || "")
  const [quantita, setQuantita] = useState<number | string>(initialData.quantita)
  const [importo, setImporto] = useState<number | string>(initialData.importo)
  const [selectedStato, setSelectedStato] = useState(initialData.id_stato_contratto || 0)
  const [selectedTipologia, setSelectedTipologia] = useState(initialData.id_tipologia_contratto || 0)
  const [dataScadenza, setDataScadenza] = useState<Dayjs | null>(dayjs(initialData.data_scadenza) || null)

  const resetFields = () => {
    // reset fields for double insertions
    setSelectedClienteId(undefined)

    if (!editEnabled) {
      setSearchClienti(" ")
    }

    setNote("")
    setQuantita(0)
    setImporto(0)
    setSelectedStato(0)
    setSelectedTipologia(0)
    setDataScadenza(null)
  }

  // stato contratto query
  useQuery({
    queryKey: [fetchStatoContrattoSelectQueryKey],
    queryFn: () => {
      if (editEnabled) {
        return fetchNomeDescrizioneForEntity(statoContrattoUri, false, false, initialData?.id)
      }

      return fetchNomeDescrizioneForEntity(statoContrattoUri, true, false, -1)
    },
    onSuccess: (getResponse) => {
      if (statoContrattoRecords.length <= 0) {
        setStatoContrattoRecords(getResponse.data)
        if (getResponse.data.length > 0) {
          setSelectedStato(getResponse.data[0].id)
        }
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // tipologia contratto query
  useQuery({
    queryKey: [fetchTipologiaContrattoSelectQueryKey],
    queryFn: () => fetchNomeDescrizioneForEntity(tipologiaContrattoUri),
    onSuccess: (getResponse) => {
      if (tipologiaContrattoRecords.length <= 0) {
        setTipologiaContrattoRecords(getResponse.data)
        if (getResponse.data.length > 0) {
          setSelectedTipologia(getResponse.data[0].id)
        }
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // search clienti query
  useQuery({
    queryKey: [fetchPFsQByKeyQueryKey, deferredSearchClienti],
    queryFn: () => fetchPFsBySearchKey(searchClienti),
    onSuccess: (response: PersonaFisicaModel[]) => {
      if (response.length > 0) {
        setClienti(response)
      } else {
        setClienti([])
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createContratto(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCommissioniQueryKey)
      // queryClient.invalidateQueries(fetchPFsQByKeyQueryKey)
      resetFields()
      if (onClose !== undefined)
        onClose()
      toggleOpen()
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editContratto(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCommissioniQueryKey)
      queryClient.invalidateQueries(fetchContrattoQueryKey)
      toggleOpen()
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchContrattoQueryKey)
    }
  }

  const handleSubmit = () => {
    if (editEnabled) {

      if (initialData === undefined) {
        return
      }

      const putModel = {} as ContrattoPostPutModel
      putModel.note = note
      putModel.quantita = Math.floor(Number(quantita)) || 0
      putModel.importo = Number(importo) || 0
      putModel.id_stato_contratto = selectedStato
      putModel.id_tipologia_contratto = initialData.id_tipologia_contratto
      putModel.id_cliente = initialData.id_cliente
      if (dataScadenza) putModel.data_scadenza = dataScadenza.format("YYYY-MM-DD")
      const putData = {} as WithRecordID
      const putContratto = {} as ContrattoRequest
      putContratto.contratto = putModel
      putData.recordID = initialData.id
      putData.data = putContratto

      editMutation.mutate(putData)
    } else {
      const x = {} as WithData
      const contratto = {} as ContrattoPostPutModel

      if (selectedClienteId !== undefined) {
        contratto.id_cliente = selectedClienteId
      }

      contratto.id_stato_contratto = selectedStato
      contratto.id_tipologia_contratto = selectedTipologia
      contratto.quantita = (Number(quantita) && Math.floor(Number(quantita))) || 0
      contratto.importo = Number(importo) || 0
      contratto.note = note
      if (dataScadenza) contratto.data_scadenza = dataScadenza.format("YYYY-MM-DD")
      const postData = {} as ContrattoRequest
      postData.contratto = contratto
      x.data = postData

      createMutation.mutate(x)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
          }
        }}
      >
        <DialogTitle>{editEnabled ? "Modifica" : "Crea Nuovo"} Contratto</DialogTitle>
        <DialogContent style={{ width: 500 }}>

          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => { setHideError(true) }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>{REQUIRED_HELPER_TEXT}</Typography>

          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            label="Quantità"
            name="quantita"
            size="small"
            value={quantita}
            onChange={e => setQuantita(e.target.value || "")}
          />

          <TextField
            margin="normal"
            required
            type="number"
            fullWidth
            label="Importo"
            name="importo"
            size="small"
            value={importo}
            onChange={e => { setImporto(e.target.value || "") }}
          />

          <Box sx={() => ({
            width: "100%",
            marginBottom: "5pt"
          })}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
              <DemoContainer components={["DatePicker"]}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Data di Scadenza"
                  name="scadenza"
                  slotProps={{ textField: { size: "small", fullWidth: true } }}
                  value={dataScadenza || null}
                  onChange={(newValue) => {
                    setDataScadenza(dayjs(newValue))
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="center">
            <br />
            {statoContrattoRecords.length > 0 ? (
              <StatoTipologiaSelectComponent
                initialId={initialData.id_stato_contratto || 0}
                disabled={false}
                label="Stato Contratto"
                records={statoContrattoRecords}
                setId={setSelectedStato}
              />
            ) : (<Skeleton animation="wave" />)}
            <br />
            {tipologiaContrattoRecords.length > 0 ? (
              <StatoTipologiaSelectComponent
                initialId={initialData.id_tipologia_contratto || 0}
                disabled={editEnabled || false}
                label="Tipologia Contratto"
                records={tipologiaContrattoRecords}
                setId={setSelectedTipologia}
              />
            ) : (<Skeleton animation="wave" />)}
          </Box>

          {!editEnabled && initialData.id_cliente === -1 && <>
            <Box display="flex" flexDirection="row" justifyContent="center" sx={{ marginTop: "10pt" }}>
              <SearchSelect
                options={clienti}
                label={"Cliente Associato"}
                required={true}
                labelPresentation={(option: PersonaFisicaModel) =>
                  `${option.nome} ${option.cognome} - ${option.codice_fiscale}`
                }
                onChange={(event, newInputValue: PersonaFisicaModel | null) => {
                  setSelectedClienteId(newInputValue?.id_cliente)
                }}
                onInputChange={(event, newInputValue: string) => {
                  setSearchClienti(newInputValue)
                }}
              />
            </Box>
          </>}

          <TextField
            margin="normal"
            label="Note"
            name="note"
            multiline
            fullWidth
            rows={4}
            value={note}
            onChange={e => {
              setNote(e.target.value)
            }}
          />
        </DialogContent>
        <DialogActions sx={{ paddingLeft: "18pt", paddingRight: "18pt", justifyContent: "space-between" }}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button onClick={() => {
              if (onClose !== undefined) {
                onClose()
              }

              if (!editEnabled) {
                resetFields()
              }

              closeActions()
            }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">{editEnabled ? "Salva" : "Crea"}</Button>
          </Box>
        </DialogActions>
      </Dialog >
    </>
  )
}

export default ContrattoFormComponent
