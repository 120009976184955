import { Button, Grid, Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box } from "@mui/material"
import React, { useState } from "react"
import { PersonaFisicaModel } from "../../../models/PersonaFisicaModel"
import EditIcon from "@mui/icons-material/Edit";
import { displayFormatted } from "../../../utils/date"
import DisplayField from "../../shared/DisplayField"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import AddIcon from "@mui/icons-material/Add"
import BeneTableComponent from "../../bene/BeneTableComponent"
import ContrattiTableComponent from "../../contratto/ContrattiTableComponent"
import CustomerCredenzialiPortaleVenditaTable from "../CustomerCredenzialiPortaleVenditaTable"
import CustomerEmailsTable from "../CustomerEmailsTable"
import CustomerIndirizziTable from "../CustomerIndirizziTable"
import CustomerTelefoniTable from "../CustomerTelefoniTable"
import PersonaFisicaFormComponent from "./PersonaFisicaFormComponent"
import { CredenzialiPortaleVenditaModel } from "../../../models/CredenzialiPortaleVenditaModel"
import { Indirizzo } from "../../../models/IndirizzoModel"
import SectionTitle from "../../shared/SectionTitle";

interface WithPF {
  pef: PersonaFisicaModel
}

const PersonaFisicaDetailComponent: React.FC<WithPF> = ({ pef }) => {

  const [mainDataEditFormOpen, setMainDataEditFormOpen] = useState<boolean>(false)
  const toggleMainDataEditFormOpen = () => setMainDataEditFormOpen(!mainDataEditFormOpen)

  // Email
  const [emailDataCreateFormOpen, setEmailDataCreateFormOpen] = useState<boolean>(false)
  const toggleEmailDataCreateFormOpen = () => setEmailDataCreateFormOpen(!emailDataCreateFormOpen)

  // Telefono
  const [telefonoDataCreateFormOpen, setTelefonoDataCreateFormOpen] = useState<boolean>(false)
  const toggleTelefonoDataEditFormOpen = () => setTelefonoDataCreateFormOpen(!telefonoDataCreateFormOpen)

  // Indirizzi
  const [indirizziDataCreateFormOpen, setIndirizziDataCreateFormOpen] = useState<boolean>(false)
  const toggleIndirizziDataEditFormOpen = () => setIndirizziDataCreateFormOpen(!indirizziDataCreateFormOpen)

  // Portali Vendita
  const [enableCreateButton, setEnableCreateButton] = useState<boolean>(true)
  const [portaliVenditaDataCreateFormOpen, setPortaliVenditaDataCreateFormOpen] = useState<boolean>(false)
  const togglePortaliVenditaDataCreateFormOpen = () => setPortaliVenditaDataCreateFormOpen(!portaliVenditaDataCreateFormOpen)

  // Beni
  const [beniDataCreateFormOpen, setBeniDataCreateFormOpen] = useState<boolean>(false)
  const toggleBeniDataEditFormOpen = () => setBeniDataCreateFormOpen(!beniDataCreateFormOpen)

  // Contratti
  const [contrattiDataCreateFormOpen, setContrattiDataCreateFormOpen] = useState<boolean>(false)
  const toggleContrattiDataEditFormOpen = () => setContrattiDataCreateFormOpen(!contrattiDataCreateFormOpen)

  return (
    <>
      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Dati Anagrafici Base" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <DisplayField title="Nome" text={pef.nome} />
              <DisplayField title="Cognome" text={pef.cognome} />
              <DisplayField title="Codice Fiscale" text={pef.codice_fiscale} />
              <DisplayField title="Genesi Cliente" text={pef.anagraficacliente.genesi_cliente} />
              <DisplayField title="Data di Nascita" text={displayFormatted(pef.data_di_nascita)} />
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => { toggleMainDataEditFormOpen() }}>Modifica</Button>
          </AccordionActions>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Info Contatto Email" />
          </AccordionSummary>
          <AccordionDetails>
            <CustomerEmailsTable
              anagrafica_cliente_id={pef.id}
              createFormOpen={emailDataCreateFormOpen}
              toggleCreateFormOpen={toggleEmailDataCreateFormOpen}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => { toggleEmailDataCreateFormOpen() }}>Aggiungi</Button>
          </AccordionActions>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Info Contatto Telefono" />
          </AccordionSummary>
          <AccordionDetails>
            <CustomerTelefoniTable
              anagrafica_cliente_id={pef.id}
              createFormOpen={telefonoDataCreateFormOpen}
              toggleCreateFormOpen={toggleTelefonoDataEditFormOpen}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => { toggleTelefonoDataEditFormOpen() }}>Aggiungi</Button>
          </AccordionActions>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Indirizzi" />
          </AccordionSummary>
          <AccordionDetails>
            <CustomerIndirizziTable
              anagrafica_cliente_id={pef.id}
              record={{} as Indirizzo}
              createFormOpen={indirizziDataCreateFormOpen}
              toggleCreateFormOpen={toggleIndirizziDataEditFormOpen}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => { toggleIndirizziDataEditFormOpen() }}>Aggiungi</Button>
          </AccordionActions>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Credenziali Portali Vendita" />
          </AccordionSummary>
          <AccordionDetails>
            <CustomerCredenzialiPortaleVenditaTable
              anagrafica_cliente_id={pef.id}
              record={{} as CredenzialiPortaleVenditaModel}
              createFormOpen={portaliVenditaDataCreateFormOpen}
              toggleCreateFormOpen={togglePortaliVenditaDataCreateFormOpen}
              setEnableCreateButton={setEnableCreateButton}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button
              disabled={!enableCreateButton}
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              onClick={() => { togglePortaliVenditaDataCreateFormOpen() }}
            >
              Aggiungi
            </Button>
          </AccordionActions>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Beni Associati" />
          </AccordionSummary>
          <AccordionDetails>
            <BeneTableComponent
              alterFabVisibility={() => toggleBeniDataEditFormOpen()}
              fabVisible={false}
              enableCreate={beniDataCreateFormOpen}
              dettaglio={false}
              anagrafica_cliente_id={pef.id_cliente}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => { toggleBeniDataEditFormOpen() }}>Aggiungi</Button>
          </AccordionActions>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Cliente - Contratti Associati" />
          </AccordionSummary>
          <AccordionDetails>
            <ContrattiTableComponent
              alterFabVisibility={() => toggleContrattiDataEditFormOpen()}
              fabVisible={false}
              enableCreate={contrattiDataCreateFormOpen}
              anagrafica_cliente_id={pef.id}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => { toggleContrattiDataEditFormOpen() }}>Aggiungi</Button>
          </AccordionActions>
        </Accordion>
      </Box>

      {/* Edit Anagrafica Base */}
      <PersonaFisicaFormComponent
        initialData={pef}
        open={mainDataEditFormOpen}
        toggleOpen={toggleMainDataEditFormOpen}
        editEnabled={true}
      />
    </>
  )
}

export default PersonaFisicaDetailComponent
