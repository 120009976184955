import axios from "axios"
import { refreshAccessToken } from "./auth";
import { getAccessToken, setAccessToken, setRefreshAccessToken } from "../utils/tokenStore";

const axiosClient = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

axiosClient.interceptors.request.use(
  async (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  console.error(originalRequest)

  if (error.response.status === 401) {
    try {
      const refresh_res = await refreshAccessToken();
      if (refresh_res === undefined) {
        return
      }

      const access_token = refresh_res.data.token;
      setAccessToken(refresh_res.data.token);
      setRefreshAccessToken(refresh_res.data.refresh_token);

      originalRequest.headers.Authorization = `Bearer ${access_token}`;

      return axiosClient(originalRequest);
    } catch (error) {
      setAccessToken("")
      setRefreshAccessToken("")
      return Promise.reject(error);
    }
  }

  return Promise.reject(error);
});

// axiosClient.defaults.validateStatus = (status) => status >= 200 && status < 300;

export { axiosClient }
