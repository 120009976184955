import { Box, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@mui/material"
import { BeneModelDetailed } from "../../models/BeneModel"
import { WithCUToggleCommandsAndData, WithRecordID, WithData } from "../../models/CommonModels"
import CredenzialiPortaleVenditaSelectTableRowComponent from "../credenzialiportalevendita/CredenzialiPortaleVenditaSelectTableRowComponent"
import { useState } from "react"
import { BeneContrattoPortaleDetailedModel, BeneContrattoPortalePostModel, BeneContrattoPortalePutModel, BeneContrattoPortaleRequest } from "../../models/BeneContrattoPortaleModel"
import { CredenzialiPortaleVenditaModel } from "../../models/CredenzialiPortaleVenditaModel"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { fetchGrafoRaggiungibilita } from "../../api/benecontratto"
import { fetchAvailableCredenzialiPortaleVenditaForBeneContrattoQueryKey, fetchBeneContrattoPortaleTableQueryKey, fetchPossibleContrattoBeneAssociationQueryKey } from "../../utils/QueryClient"
import { createBeneContrattoPortale, editBeneContrattoPortale } from "../../api/benecontrattoportale"
import { AxiosError } from "axios"
import { fromAxiosErrorToMessage } from "../../utils/crud"
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent"

interface BenePortaleFormProps extends WithCUToggleCommandsAndData<BeneContrattoPortaleDetailedModel> {
  bene: BeneModelDetailed
}

const BenePortaleFormComponent: React.FC<BenePortaleFormProps> = ({ bene, initialData, open, toggleOpen, editEnabled }) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [valutazione, setValutazione] = useState<number>(initialData.valutazione)
  const [id, setId] = useState<number>(initialData.id)

  const [credenzialiPortaleVendita, setCredenzialiPortaleVendita] = useState<CredenzialiPortaleVenditaModel[]>([])

  // grafo raggiungibilità query
  useQuery({
    queryKey: [fetchPossibleContrattoBeneAssociationQueryKey, bene.benecontratto[0].id],
    queryFn: () => fetchGrafoRaggiungibilita(bene.id),
    onSuccess: (getResponse) => {
      setCredenzialiPortaleVendita(getResponse.data.credenzialiportalevendita)
      if (getResponse.data.credenzialiportalevendita.length > 0) {
        setId(getResponse.data.credenzialiportalevendita[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createBeneContrattoPortale(x),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === fetchBeneContrattoPortaleTableQueryKey ||
          query.queryKey[0] === fetchAvailableCredenzialiPortaleVenditaForBeneContrattoQueryKey
      })
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editBeneContrattoPortale(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchBeneContrattoPortaleTableQueryKey)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchBeneContrattoPortaleTableQueryKey)
    }
  }

  const handleSubmit = () => {
    if (editEnabled) {
      const putModel = {} as BeneContrattoPortalePutModel
      putModel.valutazione = valutazione

      const putData = {} as WithRecordID
      putData.recordID = id

      const putRequest = {} as BeneContrattoPortaleRequest
      putRequest.benecontrattoportale = putModel
      putData.data = putRequest

      editMutation.mutate(putData)
    }
    else {
      const postItem = {} as BeneContrattoPortalePostModel
      postItem.id_credenziali_portale_vendita = id
      postItem.valutazione = valutazione
      postItem.id_bene_contratto = bene.benecontratto[0].id

      const postData = {} as WithData
      const postRequest = {} as BeneContrattoPortaleRequest
      postRequest.benecontrattoportale = postItem
      postData.data = postRequest

      createMutation.mutate(postData)
    }

    toggleOpen()
  }

  return (<>
    <Dialog
      open={open}
      onClose={closeActions}
      PaperProps={{
        component: "form",
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault()
        }
      }}
    >
      <DialogTitle>{editEnabled ? "Modifica" : "Carica"} su Portale Vendita</DialogTitle>
      <DialogContent style={{ width: 500 }}>
        <Box display="flex" flexDirection="column" justifyContent="center">

          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => { setHideError(true) }}
            visible={!hideError}
          />

          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: { min: 1 }
            }}
            // error={error ? error : undefined}
            margin="normal"
            type="number"
            fullWidth
            required
            label="Valutazione"
            name="valutazione"
            size="small"
            value={valutazione}
            onChange={e => {
              setValutazione(Number(e.target.value))
            }}
          />
          <br />
          {
            !editEnabled &&
            <CredenzialiPortaleVenditaSelectTableRowComponent
              setSelected={setId}
              records={credenzialiPortaleVendita}
            />
          }
        </Box>
      </DialogContent>
      <DialogActions sx={{ paddingLeft: "18pt", paddingRight: "18pt", justifyContent: "space-between" }}>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Button onClick={() => closeActions()}>Chiudi</Button>
        </Box>
        <Box sx={{ justifyContent: "flex-end" }}>
          <Button onClick={() => handleSubmit()} variant="contained">{editEnabled ? "Salva" : "Carica"}</Button>
        </Box>
      </DialogActions>
    </Dialog>
  </>)
}

export default BenePortaleFormComponent
