import React, { useState } from "react"
import { SelectChangeEvent, FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material"
import { CredenzialiPortaleVenditaModel } from "../../models/CredenzialiPortaleVenditaModel"

interface CredenzialiPortaleVenditaSelectTableRowComponentParameters {
  records: CredenzialiPortaleVenditaModel[],
  setSelected: React.Dispatch<React.SetStateAction<number>>
}

const CredenzialiPortaleVenditaSelectTableRowComponent: React.FC<CredenzialiPortaleVenditaSelectTableRowComponentParameters> =
  ({ records, setSelected }) => {

    const [candidateRecordIndex, setCandidateRecordIndex] = useState(0)
    const label = "Nome Portale"

    const handleChange = (event: SelectChangeEvent) => {
      event.preventDefault()
      let stop = false
      for (let i = 0; i < records.length && !stop; i++) {
        if (records[i].id === +event.target.value) {
          setCandidateRecordIndex(i)
          setSelected(records[i].id)
          stop = !stop
        }
      }
    };

    return (
      <>
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            value={records[candidateRecordIndex].id.toString()}
            label={label}
            fullWidth
            size="small"
            onChange={handleChange}
          >
            {
              records.map(record => (
                <MenuItem key={record.id} value={record.id}>{record.anagraficaportale.nome_portale}</MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <TextField
          margin="normal"
          type="text"
          fullWidth
          required
          disabled
          label="Link Portale"
          name="link_portale"
          size="small"
          value={records[candidateRecordIndex].anagraficaportale.link_portale}
        />

        <TextField
          margin="normal"
          type="text"
          fullWidth
          required
          disabled
          label="Credenziali"
          name="credenziali"
          size="small"
          value={records[candidateRecordIndex].credenziali_di_accesso}
        />
      </>
    )
  }

export default CredenzialiPortaleVenditaSelectTableRowComponent
