import { Typography, Grid, AccordionSummary, AccordionDetails, Accordion, Button, AccordionActions, Box } from "@mui/material"
import { BeneModelDetailed } from "../../models/BeneModel"
import { useState } from "react"
import { useQueryClient, useMutation, useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { fetchNomeDescrizioneForEntity, tipologiaBeneUri, qualitaBeneUri, collezioneBeneUri, brandBeneUri, materialeBeneUri } from "../../api/statotipologia"
import { WithData } from "../../models/CommonModels"
import { NomeDescrizione } from "../../models/StatoTipologiaModel"
import { fetchTipologiaBeneSelectQueryKey, fetchQualitaBeneSelectQueryKey, fetchCollezioneBeneSelectQueryKey, fetchBrandBeneSelectQueryKey, fetchMaterialeBeneSelectQueryKey, fetchAllFinalConditionsQueryKey, fetchAvailableCredenzialiPortaleVenditaForBeneContrattoQueryKey, fetchBeneContrattoPortaleTableQueryKey } from "../../utils/QueryClient"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import VenditaBeneContrattoComponent from "../venditabenecontratto/VenditaBeneContrattoComponent"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import BeneFormComponent from "./BeneFormComponent"
import DisplayField from "../shared/DisplayField"
import { displayFormatted } from "../../utils/date"
import BeneCondizioneFormComponent from "../benecontratto/BeneCondizioneFormComponent"
import { fetchAllFinalConditions } from "../../api/condizioni"
import { LoadingButton } from "@mui/lab"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import { deleteBeneContrattoPortale, fetchPaginatedBeneContrattoPortale } from "../../api/benecontrattoportale"
import { BeneContrattoPortaleDetailedModel } from "../../models/BeneContrattoPortaleModel"
import { ManageHistory as ManageHistoryIcon } from "@mui/icons-material"
import PortaleVenditaHistory from "../benecontrattoportale/PortaleVenditaHistory"
import BenePortaleFormComponent from "../benecontrattoportale/BenePortaleFormComponent"
import SectionTitle from "../shared/SectionTitle"

interface BeneDetailComponentParams {
  bene: BeneModelDetailed
}

const BeneDetailComponent: React.FC<BeneDetailComponentParams> = ({ bene }) => {

  const navigator = useNavigate()
  const queryClient = useQueryClient()

  const [tipologiaBeneRecords, setTipologiaBeneRecords] = useState<NomeDescrizione[]>([])
  const [qualitaBeneRecords, setqualitaBeneRecords] = useState<NomeDescrizione[]>([])
  const [collezioneBeneRecords, setcollezioneBeneRecords] = useState<NomeDescrizione[]>([])
  const [brandBeneRecords, setbrandBeneRecords] = useState<NomeDescrizione[]>([])
  const [materialeBeneRecords, setmaterialeBeneRecords] = useState<NomeDescrizione[]>([])

  const [finalStates, setFinalStates] = useState<string[]>([])
  const [portaleInfo, setPortaleInfo] = useState<BeneContrattoPortaleDetailedModel>(undefined as any)
  const [showHistory, setShowHistory] = useState<boolean>(false)

  const [mainDataEditFormOpen, setMainDataEditFormOpen] = useState<boolean>(false)
  const toggleMainDataEditFormOpen = () => setMainDataEditFormOpen(!mainDataEditFormOpen)

  const [conditionDataEditFormOpen, setConditionDataEditFormOpen] = useState<boolean>(false)
  const toggleConditionDataEditFormOpen = () => setConditionDataEditFormOpen(!conditionDataEditFormOpen)

  const [sellPortalEditFormOpen, setSellPortalEditFormOpen] = useState<boolean>(false)
  const toggleSellPortalEditFormOpen = () => setSellPortalEditFormOpen(!sellPortalEditFormOpen)

  // final conditions query
  useQuery({
    queryKey: [fetchAllFinalConditionsQueryKey],
    queryFn: () => {
      return fetchAllFinalConditions()
    },
    onSuccess: (getResponse) => {
      setFinalStates(getResponse.data.data.map(cr => cr.nome))
    }, refetchOnWindowFocus: false
  })

  // bene contratto portale query
  useQuery([fetchBeneContrattoPortaleTableQueryKey, 1, bene.benecontratto[0].id],
    () => {
      return fetchPaginatedBeneContrattoPortale(1, bene.benecontratto[0].id)
    },
    {
      keepPreviousData: true, onSuccess: (getResponse) => {
        setPortaleInfo(getResponse.data.data[0])
      }, refetchOnWindowFocus: false
    })

  const deletePortaleInfoMutation = useMutation({
    mutationFn: (x: WithData) => deleteBeneContrattoPortale(x),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey[0] === fetchBeneContrattoPortaleTableQueryKey ||
          query.queryKey[0] === fetchAvailableCredenzialiPortaleVenditaForBeneContrattoQueryKey
      })
    },
    // onError: (e: any) => {
    //   if (e.response.data.message !== undefined) setErrorMessage(e.response.data.message)
    //   else setErrorMessage("Errore non previsto, riprovare o richiedere assistenza")
    //   setError(true)
    // }
  })

  // tipologia bene query
  useQuery({
    queryKey: [fetchTipologiaBeneSelectQueryKey],
    queryFn: () => {
      return fetchNomeDescrizioneForEntity(tipologiaBeneUri)
    },
    onSuccess: (getResponse) => {
      if (tipologiaBeneRecords.length <= 0) {
        setTipologiaBeneRecords(getResponse.data)
        if (getResponse.data.length <= 0) navigator("/bene", { replace: true })
      }
    }, refetchOnWindowFocus: false
  })

  // qualità bene query
  useQuery({
    queryKey: [fetchQualitaBeneSelectQueryKey],
    queryFn: () => {
      return fetchNomeDescrizioneForEntity(qualitaBeneUri)
    },
    onSuccess: (getResponse) => {
      if (qualitaBeneRecords.length <= 0) {
        setqualitaBeneRecords(getResponse.data)
        if (getResponse.data.length <= 0) navigator("/bene", { replace: true })
      }
    }, refetchOnWindowFocus: false
  })

  // collezione bene query
  useQuery({
    queryKey: [fetchCollezioneBeneSelectQueryKey],
    queryFn: () => {
      return fetchNomeDescrizioneForEntity(collezioneBeneUri)
    },
    onSuccess: (getResponse) => {
      if (collezioneBeneRecords.length <= 0) {
        setcollezioneBeneRecords(getResponse.data)
        if (getResponse.data.length <= 0) navigator("/bene", { replace: true })
      }
    }, refetchOnWindowFocus: false
  })

  // brand bene query
  useQuery({
    queryKey: [fetchBrandBeneSelectQueryKey],
    queryFn: () => {
      return fetchNomeDescrizioneForEntity(brandBeneUri)
    },
    onSuccess: (getResponse) => {
      if (brandBeneRecords.length <= 0) {
        setbrandBeneRecords(getResponse.data)
        if (getResponse.data.length <= 0) navigator("/bene", { replace: true })
      }
    }, refetchOnWindowFocus: false
  })

  // materiale bene query
  useQuery({
    queryKey: [fetchMaterialeBeneSelectQueryKey],
    queryFn: () => {
      return fetchNomeDescrizioneForEntity(materialeBeneUri)
    },
    onSuccess: (getResponse) => {
      if (materialeBeneRecords.length <= 0) {
        setmaterialeBeneRecords(getResponse.data)
        if (getResponse.data.length <= 0) navigator("/bene", { replace: true })
      }
    }, refetchOnWindowFocus: false
  })

  const portaleVisible = () => {
    for (const storico_record of bene.benecontratto[0].storicocondizionebenecontratto)
      if (storico_record.condizione.aggiunta_portale) return true
    return false
  }

  const venditaVisible = () => {
    for (const storico_record of bene.benecontratto[0].storicocondizionebenecontratto)
      if (storico_record.condizione.aggiunta_vendita) return true
    return false
  }

  const isFinalState = (state: string) => {
    for (let fs of finalStates) {
      if (fs === state) {
        return true
      }
    }
    return false
  }

  return (
    <>
      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Bene - Dati Principali" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <DisplayField title="Ubicazione" text={bene.ubicazione} />
              <DisplayField title="Categoria" text={bene.tipologiabene.nome} />
              <DisplayField title="Sotto-Categoria" text={bene.sottotipologiabene?.nome || "Non disponibile"} />
              <DisplayField title="Stato" text={bene.qualitabene.nome} />
              <DisplayField title="Brand" text={bene.brandbene?.nome || "Non disponibile"} />
              <DisplayField title="Contratto" text={bene.benecontratto[0].contratto?.titolo || "Non associato"} />
              <DisplayField title="Collezione" text={bene.collezionebene?.nome || "Non disponibile"} />
              <DisplayField title="Materiale" text={bene.materialebene?.nome || "Non disponibile"} />
              <DisplayField title="Colore" text={bene.colorebene?.nome || "Non disponibile"} />
              <DisplayField title="Cliente Associato" text={
                (bene.anagraficacliente.personafisica?.nome) + (" " + bene.anagraficacliente.personafisica?.cognome)
              } />
              <DisplayField title="Valutazione" text={[bene.valutazione_minima, bene.valutazione_massima].join(" / ") + " €"} />
              <DisplayField title="Edizione Limitata" text={bene.edizione_limitata ? "Si" : "No"} />
              <DisplayField title="Anno di Produzione" text={displayFormatted(bene.anno_produzione).split("/")[2]} />
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <DisplayField fill={10} title="Note" text={bene.note || "Non ci sono note"} />
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => { setMainDataEditFormOpen(true); }}>Modifica</Button>
          </AccordionActions>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            <SectionTitle title="Gestione Delle Condizioni" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <DisplayField title="Contratto Associato" text={bene.benecontratto[0].contratto?.titolo || "Nessun Contratto associato"} />
              <DisplayField title="Condizione Articolo" text={bene.benecontratto[0].condizione.nome} />
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <LoadingButton
              disabled={isFinalState(bene.benecontratto[0].condizione.nome) ? true : false}
              loading={finalStates.length <= 0 ? true : false}
              variant="contained"
              color="primary"
              startIcon={<EditIcon />} onClick={() => { setConditionDataEditFormOpen(true); }}
            >
              Modifica
            </LoadingButton>
          </AccordionActions>
        </Accordion>

        {portaleVisible() && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <SectionTitle title="Portale Vendita" />
            </AccordionSummary>
            <AccordionDetails>
              {
                portaleInfo !== undefined ?
                  <>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <DisplayField title="Valutazione" text={"" + portaleInfo.valutazione + " €"} />
                      <DisplayField title="Nome Portale" text={portaleInfo.credenzialiportalevendita.anagraficaportale.nome_portale} />
                      <DisplayField title="Link Portale" text={portaleInfo.credenzialiportalevendita.anagraficaportale.link_portale} link />
                      <DisplayField title="Credenziali" text={portaleInfo.credenzialiportalevendita.credenziali_di_accesso} />
                    </Grid>
                    {
                      showHistory && <Box display={"flex"} justifyContent={"center"}>
                        <PortaleVenditaHistory history={portaleInfo.storicocambioprezzobene} />
                      </Box>
                    }
                  </>
                  : <Typography>Nessun portale di vendita selezionato per questo bene</Typography>
              }

            </AccordionDetails>
            <AccordionActions>
              <Button
                disabled={!bene.benecontratto[0].condizione.aggiunta_portale || portaleInfo !== undefined}
                variant="contained"
                color="success"
                startIcon={<AddIcon />} onClick={() => { toggleSellPortalEditFormOpen() }}
              >
                Aggiungi Portale Vendita
              </Button>
              <Button
                disabled={!bene.benecontratto[0].condizione.aggiunta_portale || portaleInfo === undefined}
                variant="contained"
                color="primary"
                startIcon={<EditIcon />} onClick={() => { toggleSellPortalEditFormOpen() }}
              >
                Modifica
              </Button>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<ManageHistoryIcon />} onClick={() => { setShowHistory(!showHistory) }}
              >
                {showHistory ? "Nascondi Storia" : "Vedi Storia"}
              </Button>
              <Button
                disabled={!bene.benecontratto[0].condizione.aggiunta_portale}
                variant="contained"
                color="error"
                startIcon={<DeleteForeverIcon />} onClick={() => {
                  const x = {} as WithData
                  x.data = portaleInfo.id
                  deletePortaleInfoMutation.mutate(x)
                }}
              >
                Elimina
              </Button>
            </AccordionActions>
          </Accordion>
        )}

        {venditaVisible() && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <SectionTitle title="Dati Vendita" />
            </AccordionSummary>
            <AccordionDetails>
              <VenditaBeneContrattoComponent bene={bene} />
            </AccordionDetails>
          </Accordion>
        )}
      </Box>

      {/* Edit */}
      <BeneFormComponent
        initialData={bene}
        open={mainDataEditFormOpen}
        toggleOpen={toggleMainDataEditFormOpen}
        editEnabled={true}
      />

      {/* Edit Condizioni */}
      <BeneCondizioneFormComponent
        initialData={bene}
        open={conditionDataEditFormOpen}
        toggleOpen={toggleConditionDataEditFormOpen}
        editEnabled={true} // TODO: verify
      />

      {/* Create Portale Vendita */}
      {portaleVisible() && portaleInfo === undefined && <BenePortaleFormComponent
        initialData={{} as BeneContrattoPortaleDetailedModel}
        bene={bene}
        open={sellPortalEditFormOpen}
        toggleOpen={toggleSellPortalEditFormOpen}
        editEnabled={false}
      />}

      {/* Edit Portale Vendita */}
      {portaleVisible() && portaleInfo !== undefined && <BenePortaleFormComponent
        initialData={portaleInfo}
        bene={bene}
        open={sellPortalEditFormOpen}
        toggleOpen={toggleSellPortalEditFormOpen}
        editEnabled={portaleInfo !== undefined}
      />}
    </>
  )
}

export default BeneDetailComponent
