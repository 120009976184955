import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { createBene, editBene } from "../../api/beni"
import { WithCUToggleCommandsAndData, WithRecordID, WithData } from "../../models/CommonModels"
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, InputAdornment, Skeleton, TextField, Typography } from "@mui/material"
import { BeneModelDetailed, BenePostModel, BenePutModel, BeneRequest } from "../../models/BeneModel"
import { fetchBeniQueryKey, fetchDetailedBeneQuery, fetchPFsQByKeyQueryKey } from "../../utils/QueryClient"
import dayjs, { Dayjs } from "dayjs"
import StatoTipologiaSelectComponent from "../shared/StatoTipologiaSelectComponent"
import { NomeDescrizione } from "../../models/StatoTipologiaModel"
import { fromAxiosErrorToMessage, prepareCreateData, prepareEditData } from "../../utils/crud"
import { PersonaFisicaModel } from "../../models/PersonaFisicaModel"
import { fetchPFsBySearchKey } from "../../api/personafisica"
import { useDebounce } from "@uidotdev/usehooks"
import SearchSelect from "../shared/SearchSelect"
import { REQUIRED_HELPER_TEXT } from "../../utils/form"
import "dayjs/locale/it";
import { AxiosError } from "axios"
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent"
import DynamicSearchSelect, { SearchElementType } from "../shared/DynamicSearchSelect"
import { createColoreBene, getBeneColori } from "../../api/bene/colore"
import { BeneColoreModel } from "../../models/api/response/bene/BeneColoreModel"
import { SortingModel } from "../../models/shared/SortingModel"
import { createBrandBene, getBeneBrands } from "../../api/bene/brand"
import { createTipologiaBene, getTipologie } from "../../api/bene/tipologia"
import { BeneBrandModel } from "../../models/api/response/bene/BeneBrandModel"
import { BeneMaterialeModel } from "../../models/api/response/bene/BeneMaterialeModel"
import { BeneCondizioneModel } from "../../models/api/response/bene/BeneCondizioneModel"
import { BeneTipologiaModel } from "../../models/api/response/bene/BeneTipologiaModel"
import { BeneSottoTipologiaModel } from "../../models/api/response/bene/BeneSottoTipologiaModel"
import { createSottoTipologiaBene, getSottotipologie } from "../../api/bene/sottotipologia"
import { createCondizioneBene, getCondizione } from "../../api/bene/condizione"
import { createMaterialeBene, getMateriale } from "../../api/bene/materiale"
import { createCollezioneBene, getCollezione } from "../../api/bene/collezione"

export interface BeneFormComponentProps extends WithCUToggleCommandsAndData<BeneModelDetailed> {
  beniPerCliente?: number
}

const BeneFormComponent: React.FC<BeneFormComponentProps> = ({
  editEnabled,
  onClose,
  initialData,
  open,
  toggleOpen,
  beniPerCliente
}) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [ubicazioneBeneRecords] = useState<NomeDescrizione[]>([
    {
      id: 1,
      nome: "Showroom",
      descrizione: ""
    },
    {
      id: 2,
      nome: "Cliente",
      descrizione: ""
    }
  ])

  const [qualitaBeneRecords, setQualitaBeneRecords] = useState<BeneCondizioneModel[]>([])
  const [qualitaBeneLoading, setBrandQualitaLoading] = useState<boolean>(false)
  const [searchQualitaBeneKey, setSearchQualitaBeneKey] = useState<string>("")
  const deferredSearchQualitaBeneKey = useDebounce(searchQualitaBeneKey, 1000);

  const [collezioneBeneRecords, setCollezioneBeneRecords] = useState<NomeDescrizione[]>([]) // TODO: change
  const [collezioneBeneLoading, setCollezioneBeneLoading] = useState<boolean>(false)
  const [searchCollezioneBeneKey, setSearchCollezioneBeneKey] = useState<string>("")
  const deferredSearchCollezioneBeneKey = useDebounce(searchCollezioneBeneKey, 1000);

  const [materialeBeneRecords, setMaterialeBeneRecords] = useState<BeneMaterialeModel[]>([])
  const [materialeBeneLoading, setMaterialeBeneLoading] = useState<boolean>(false)
  const [searchMaterialeBeneKey, setSearchMaterialeBeneKey] = useState<string>("")
  const deferredSearchMaterialeBeneKey = useDebounce(searchMaterialeBeneKey, 1000);

  const [brandBeneRecords, setBrandBeneRecords] = useState<BeneBrandModel[]>([])
  const [brandBeneLoading, setBrandBeneLoading] = useState<boolean>(false)
  const [searchBrandBeneKey, setSearchBrandBeneKey] = useState<string>("")
  const deferredSearchBrandBeneKey = useDebounce(searchBrandBeneKey, 1000);

  const [tipologiaBeneRecords, setTipologiaBeneRecords] = useState<BeneTipologiaModel[]>([])
  const [tipologiaLoading, setTipologiaLoading] = useState<boolean>(false)
  const [searchTipologiaKey, setSearchTipologiaKey] = useState<string>("")
  const deferredSearchTipologiaKey = useDebounce(searchTipologiaKey, 1000);

  const [sottoTipologiaBeneRecords, setSottoTipologiaBeneRecords] = useState<BeneSottoTipologiaModel[]>([])
  const [sottoTipologiaLoading, setSottoTipologiaLoading] = useState<boolean>(false)
  const [searchSottoTipologiaKey, setSearchSottoTipologiaKey] = useState<string>("")
  const deferredSearchSottoTipologiaKey = useDebounce(searchSottoTipologiaKey, 1000);

  const [coloreBeneRecords, setColoreBeneRecords] = useState<BeneColoreModel[]>([])
  const [coloreLoading, setColoreLoading] = useState<boolean>(false)
  const [searchColoreKey, setSearchColoreKey] = useState<string>("")
  const deferredSearchColoreKey = useDebounce(searchColoreKey, 1000);

  const [clienti, setClienti] = useState<PersonaFisicaModel[]>([])
  const [searchClienti, setSearchClienti] = useState<string>()
  const deferredSearchClienti = useDebounce(searchClienti, 1000);

  const [annoProduzione, setAnnoProduzione] = useState<Dayjs | undefined>(initialData.anno_produzione !== undefined ? dayjs(initialData.anno_produzione?.replace("/", "-")) : undefined)
  const [valutazioneMinima, setValutazioneMinima] = useState<number | string>(initialData.valutazione_minima || "")
  const [valutazioneMassima, setValutazioneMassima] = useState<number | string>(initialData.valutazione_massima || "")
  const [note, setNote] = useState<string>(initialData.note || "")
  const [edizioneLimitata, setEdizioneLimitata] = useState<boolean>(initialData.edizione_limitata || false)

  const [selectedUbicazione, setSelectedUbicazione] = useState<string>(initialData.ubicazione || ubicazioneBeneRecords[0].nome)

  const [selectedTipologiaId, setSelectedTipologiaId] = useState<number>(initialData.id_tipologia_bene || 0)
  const [selectedSottoTipologiaId, setSelectedSottoTipologiaId] = useState<number>(initialData.id_sotto_tipologia_bene || 0)
  const [selectedQualitaId, setSelectedQualitaId] = useState<number>(initialData.id_qualita_bene || 0)
  const [selectedCollezioneId, setSelectedCollezioneId] = useState<number>(initialData.id_collezione_bene || 0)
  const [selectedBrandId, setSelectedBrandId] = useState<number>(initialData.id_brand_bene || 0)
  const [selectedMaterialeId, setSelectedMaterialeId] = useState<number>(initialData.id_materiale_bene || 0)
  const [selectedColoreId, setSelectedColoreId] = useState<number>(initialData.id_colore_bene || 0)

  const [selectedClienteId, setSelectedClienteId] = useState<number | undefined>(initialData.id_cliente)

  const [error, setError] = useState<boolean>(false)

  const resetFields = () => {
    // TODO: verify all the below
    // reset fields for double insertions
    if (!editEnabled) {
      setSearchClienti(" ")
    }
    setSelectedUbicazione("Showroom")
    setAnnoProduzione(undefined)
    setValutazioneMinima(initialData.valutazione_minima)
    setValutazioneMassima(initialData.valutazione_massima)
    setNote("")
    setSelectedCollezioneId(0)
    setSelectedBrandId(0)
    setEdizioneLimitata(false)
    setSelectedTipologiaId(1)
    setSelectedQualitaId(1)
    setSelectedMaterialeId(1)
  }

  // search clienti query
  useQuery({
    queryKey: [fetchPFsQByKeyQueryKey, deferredSearchClienti],
    queryFn: () => fetchPFsBySearchKey(searchClienti),
    onSuccess: (response: PersonaFisicaModel[]) => {
      if (response.length > 0) {
        setClienti(response)
      } else {
        setClienti([])
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // tipologia beni query
  useQuery({
    queryKey: [deferredSearchTipologiaKey, "tipologia"],
    queryFn: () => getTipologie(selectedTipologiaId, 0, { field: "id", direction: "DESC" } as SortingModel),
    onSuccess: (response) => {
      setTipologiaBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedTipologiaId === 0) {
        setSelectedTipologiaId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // sotto-tipologia beni query
  useQuery({
    queryKey: [deferredSearchSottoTipologiaKey, selectedTipologiaId, "sotto-tipologia"],
    queryFn: () => getSottotipologie(selectedSottoTipologiaId, 0, { field: "id", direction: "DESC" } as SortingModel, selectedTipologiaId),
    onSuccess: (response) => {
      setSottoTipologiaBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedSottoTipologiaId === 0) {
        setSelectedSottoTipologiaId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // qualità bene query
  useQuery({
    queryKey: [deferredSearchQualitaBeneKey, "qualita"],
    queryFn: () => getCondizione(selectedQualitaId, 0, { field: "id", direction: "DESC" } as SortingModel),
    onSuccess: (response) => {
      setQualitaBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedQualitaId === 0) {
        setSelectedQualitaId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // collezione bene query
  useQuery({
    queryKey: [deferredSearchCollezioneBeneKey, "collezione"],
    queryFn: () => getCollezione(selectedCollezioneId, 0, { field: "id", direction: "DESC" } as SortingModel),
    onSuccess: (response) => {
      setCollezioneBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedCollezioneId === 0) {
        setSelectedCollezioneId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // brand bene query
  useQuery({
    queryKey: [deferredSearchBrandBeneKey, "brand"],
    queryFn: () => getBeneBrands(selectedBrandId, 0, { field: "id", direction: "DESC" } as SortingModel),
    onSuccess: (response) => {
      setBrandBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedBrandId === 0) {
        setSelectedBrandId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // materiale bene query
  useQuery({
    queryKey: [deferredSearchMaterialeBeneKey, "materiale"],
    queryFn: () => getMateriale(selectedMaterialeId, 0, { field: "id", direction: "DESC" } as SortingModel),
    onSuccess: (response) => {
      setMaterialeBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedMaterialeId === 0) {
        setSelectedMaterialeId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  // colore bene query
  useQuery({
    queryKey: [deferredSearchColoreKey, "colore"],
    queryFn: () => getBeneColori(selectedColoreId, 0, { field: "id", direction: "DESC" } as SortingModel),
    onSuccess: (response) => {
      setColoreBeneRecords(response.data.data)
      if (response.data.data.length > 0 && selectedColoreId === 0) {
        setSelectedColoreId(response.data.data[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createBene(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchBeniQueryKey)
      queryClient.invalidateQueries(fetchPFsQByKeyQueryKey)
      resetFields()
      if (onClose !== undefined)
        onClose()
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editBene(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchBeniQueryKey)
      queryClient.invalidateQueries(fetchDetailedBeneQuery)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchBeniQueryKey)
      queryClient.invalidateQueries(fetchDetailedBeneQuery)
    }
  }

  const handleSubmit = () => {
    if (editEnabled) {

      if (initialData === undefined) {
        return
      }

      const benePut = {} as BenePutModel
      benePut.valutazione_minima = Number(valutazioneMinima) || 1
      benePut.valutazione_massima = Number(valutazioneMassima) || 1
      benePut.id_tipologia_bene = selectedTipologiaId
      benePut.id_sotto_tipologia_bene = selectedSottoTipologiaId
      benePut.ubicazione = selectedUbicazione
      benePut.note = note

      benePut.id_collezione_bene = selectedCollezioneId === 0 ? undefined : selectedCollezioneId
      benePut.id_brand_bene = selectedBrandId === 0 ? undefined : selectedBrandId
      benePut.id_materiale_bene = selectedMaterialeId
      benePut.id_colore_bene = selectedColoreId
      benePut.id_qualita_bene = selectedQualitaId

      benePut.edizione_limitata = edizioneLimitata

      if (annoProduzione !== undefined) {
        benePut.anno_produzione = annoProduzione.format("YYYY/MM/DD")
      }

      const request = {} as BeneRequest
      request.bene = benePut

      editMutation.mutate(prepareEditData(initialData.id, request))
    } else {
      const benePost = {} as BenePostModel

      if (selectedClienteId !== undefined) {
        benePost.id_cliente = selectedClienteId
      }

      benePost.id_tipologia_bene = selectedTipologiaId
      benePost.id_sotto_tipologia_bene = selectedSottoTipologiaId
      benePost.ubicazione = selectedUbicazione
      benePost.note = note
      benePost.valutazione_massima = Number(valutazioneMassima) || 1
      benePost.valutazione_minima = Number(valutazioneMinima) || 1
      benePost.id_collezione_bene = selectedCollezioneId === 0 ? undefined : selectedCollezioneId
      benePost.id_brand_bene = selectedBrandId === 0 ? undefined : selectedBrandId
      benePost.id_materiale_bene = selectedMaterialeId
      benePost.id_colore_bene = selectedColoreId
      benePost.id_qualita_bene = selectedQualitaId
      benePost.edizione_limitata = edizioneLimitata

      if (annoProduzione !== undefined && annoProduzione.isValid()) {
        benePost.anno_produzione = annoProduzione.format("YYYY/MM/DD")
      }

      const request = {} as BeneRequest
      request.bene = benePost

      createMutation.mutate(prepareCreateData(request))
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>
          {editEnabled ? "Modifica" : "Crea Nuovo"} Bene {beniPerCliente !== undefined ? `# ${beniPerCliente + 1} per Cliente` : ''}
        </DialogTitle>

        <DialogContent style={{ width: 500 }}>

          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => { setHideError(true) }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>{REQUIRED_HELPER_TEXT}</Typography>

          <Box display="flex" flexDirection="column" justifyContent="center" sx={{ marginTop: "15px" }}>
            <StatoTipologiaSelectComponent
              initialId={initialData.id_materiale_bene || 1}
              disabled={false}
              required={true}
              label="Ubicazione"
              records={ubicazioneBeneRecords}
              setId={(id) => setSelectedUbicazione(ubicazioneBeneRecords.filter(r => r.id === id)[0].nome)}
            />
          </Box>

          <Box display="flex" justifyContent="center">
            <TextField
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                inputProps: { min: 1 }
              }}
              error={error ? error : undefined}
              margin="normal"
              type="number"
              fullWidth
              required
              label="Valutazione Minima"
              name="valutazione_minima"
              size="small"
              value={valutazioneMinima}
              onChange={e => {
                setError(false)
                setValutazioneMinima(e.target.value || "")
              }}
            />
            <TextField
              sx={{ marginLeft: "5px" }}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
                inputProps: { min: 1 }
              }}
              error={error ? error : undefined}
              margin="normal"
              type="number"
              fullWidth
              required
              label="Valutazione Massima"
              name="valutazione_massima"
              size="small"
              value={valutazioneMassima}
              onChange={e => {
                setError(false)
                setValutazioneMassima(e.target.value || "")
              }}
            />
          </Box>

          <Box display="flex" justifyContent="left">
            <FormControlLabel control={
              <Checkbox checked={edizioneLimitata ? true : false} onClick={() => setEdizioneLimitata(!edizioneLimitata)} />
            } label="Edizione Limitata" />
          </Box>

          <br />
          <Box display="flex" flexDirection="column" justifyContent="center">
            <DynamicSearchSelect
              label="Collezione Bene"
              selectedOptionId={selectedCollezioneId.toString()}
              options={collezioneBeneRecords.map(en => {
                return {
                  id: en.id,
                  name: en.nome
                } as unknown as SearchElementType
              })}
              required={true}
              loading={collezioneBeneLoading}
              onNewElementAdded={
                async (newElement) => {
                  if (newElement.id === undefined) {
                    let newId = await createCollezioneBene({
                      data: {
                        collezionebene: {
                          nome: newElement.name
                        }
                      }
                    } as unknown as WithData);
                    setSelectedCollezioneId(newId.data.id)
                    setCollezioneBeneRecords([...collezioneBeneRecords, newId.data])
                  }
                }
              }
              onChange={(event, newInputValue: SearchElementType | null) => {
                setSelectedCollezioneId(Number(newInputValue?.id))
              }}
              onInputChange={(event, newInputValue: string) => {
                setCollezioneBeneLoading(true)
                setSearchCollezioneBeneKey(newInputValue)
              }}
            />
            <br />
            <DynamicSearchSelect
              label="Materiale Bene"
              selectedOptionId={selectedMaterialeId.toString()}
              options={materialeBeneRecords.map(en => {
                return {
                  id: en.id,
                  name: en.nome
                } as unknown as SearchElementType
              })}
              required={true}
              loading={materialeBeneLoading}
              onNewElementAdded={
                async (newElement) => {
                  if (newElement.id === undefined) {
                    let newId = await createMaterialeBene({
                      data: {
                        materialebene: {
                          nome: newElement.name
                        }
                      }
                    } as unknown as WithData);
                    setSelectedMaterialeId(newId.data.id)
                    setMaterialeBeneRecords([...materialeBeneRecords, newId.data])
                  }
                }
              }
              onChange={(event, newInputValue: SearchElementType | null) => {
                setSelectedMaterialeId(Number(newInputValue?.id))
              }}
              onInputChange={(event, newInputValue: string) => {
                setMaterialeBeneLoading(true)
                setSearchMaterialeBeneKey(newInputValue)
              }}
            />
            <br />
            <DynamicSearchSelect
              label="Colore Bene"
              selectedOptionId={selectedColoreId.toString()}
              options={coloreBeneRecords.map(en => {
                return {
                  id: en.id,
                  name: en.nome
                } as unknown as SearchElementType
              })}
              required={true}
              loading={coloreLoading}
              onNewElementAdded={
                async (newElement) => {
                  if (newElement.id === undefined) {
                    let newId = await createColoreBene({
                      data: {
                        colore_bene: {
                          nome: newElement.name
                        }
                      }
                    } as unknown as WithData);
                    setSelectedColoreId(newId.data.id)
                    setColoreBeneRecords([...coloreBeneRecords, newId.data])
                  }
                }
              }
              onChange={(event, newInputValue: SearchElementType | null) => {
                setSelectedColoreId(Number(newInputValue?.id))
              }}
              onInputChange={(event, newInputValue: string) => {
                setColoreLoading(true)
                setSearchColoreKey(newInputValue)
              }}
            />
            <br />
            <DynamicSearchSelect
              label="Brand"
              selectedOptionId={selectedBrandId.toString()}
              options={brandBeneRecords.map(en => {
                return {
                  id: en.id,
                  name: en.nome
                } as unknown as SearchElementType
              })}
              required={true}
              loading={brandBeneLoading}
              onNewElementAdded={
                async (newElement) => {
                  if (newElement.id === undefined) {
                    let newId = await createBrandBene({
                      data: {
                        brandbene: {
                          nome: newElement.name
                        }
                      }
                    } as unknown as WithData);
                    setSelectedBrandId(newId.data.id)
                    setBrandBeneRecords([...brandBeneRecords, newId.data])
                  }
                }
              }
              onChange={(event, newInputValue: SearchElementType | null) => {
                setSelectedBrandId(Number(newInputValue?.id))
              }}
              onInputChange={(event, newInputValue: string) => {
                setBrandBeneLoading(true)
                setSearchBrandBeneKey(newInputValue)
              }}
            />
            <br />
            <DynamicSearchSelect
              label="Tipologia"
              selectedOptionId={selectedTipologiaId.toString()}
              options={tipologiaBeneRecords.map(en => {
                return {
                  id: en.id,
                  name: en.nome
                } as unknown as SearchElementType
              })}
              required={true}
              loading={tipologiaLoading}
              onNewElementAdded={
                async (newElement) => {
                  if (newElement.id === undefined) {
                    let newId = await createTipologiaBene({
                      data: {
                        tipologiabene: {
                          nome: newElement.name
                        }
                      }
                    } as unknown as WithData);
                    setSelectedTipologiaId(newId.data.id)
                    setTipologiaBeneRecords([...tipologiaBeneRecords, newId.data])
                  }
                }
              }
              onChange={(event, newInputValue: SearchElementType | null) => {
                setSelectedSottoTipologiaId(0)
                setSelectedTipologiaId(Number(newInputValue?.id))
              }}
              onInputChange={(event, newInputValue: string) => {
                setTipologiaLoading(true)
                setSearchTipologiaKey(newInputValue)
              }}
            />
            <br />
            {tipologiaBeneRecords.length > 0 ? (
              <DynamicSearchSelect
                label="Sotto-Tipologia"
                selectedOptionId={selectedSottoTipologiaId.toString()}
                options={sottoTipologiaBeneRecords.map(en => {
                  return {
                    id: en.id,
                    name: en.nome
                  } as unknown as SearchElementType
                })}
                required={true}
                loading={sottoTipologiaLoading}
                onNewElementAdded={
                  async (newElement) => {
                    if (newElement.id === undefined) {
                      let newId = await createSottoTipologiaBene({
                        data: {
                          sotto_tipologia_bene: {
                            nome: newElement.name,
                            id_tipologia_bene: selectedTipologiaId
                          }
                        }
                      } as unknown as WithData);
                      setSelectedSottoTipologiaId(newId.data.id)
                      setSottoTipologiaBeneRecords([...sottoTipologiaBeneRecords, newId.data])
                    }
                  }
                }
                onChange={(event, newInputValue: SearchElementType | null) => {
                  setSelectedSottoTipologiaId(Number(newInputValue?.id))
                }}
                onInputChange={(event, newInputValue: string) => {
                  setSottoTipologiaLoading(true)
                  setSearchSottoTipologiaKey(newInputValue)
                }}
              />
            ) : (<Skeleton animation="wave" />)}
            <br />
            <DynamicSearchSelect
              label="Stato"
              selectedOptionId={selectedQualitaId.toString()}
              options={qualitaBeneRecords.map(en => {
                return {
                  id: en.id,
                  name: en.nome
                } as unknown as SearchElementType
              })}
              required={true}
              loading={qualitaBeneLoading}
              onNewElementAdded={
                async (newElement) => {
                  if (newElement.id === undefined) {
                    let newId = await createCondizioneBene({
                      data: {
                        qualitabene: {
                          nome: newElement.name
                        }
                      }
                    } as unknown as WithData);
                    setSelectedQualitaId(newId.data.id)
                    setQualitaBeneRecords([...qualitaBeneRecords, newId.data])
                  }
                }
              }
              onChange={(event, newInputValue: SearchElementType | null) => {
                setSelectedQualitaId(Number(newInputValue?.id))
              }}
              onInputChange={(event, newInputValue: string) => {
                setBrandQualitaLoading(true)
                setSearchQualitaBeneKey(newInputValue)
              }}
            />
            <br />
          </Box>

          <TextField
            error={error ? error : undefined}
            margin="normal"
            label="Note"
            name="note"
            multiline
            fullWidth
            rows={4}
            value={note}
            onChange={e => {
              setError(false)
              setNote(e.target.value)
            }}
          />

          {!editEnabled && initialData.id_cliente === -1 && <>
            <Box display="flex" flexDirection="row" justifyContent="center" sx={{ marginTop: "10pt" }}>
              <SearchSelect
                options={clienti}
                label={"Cliente Associato"}
                required={true}
                labelPresentation={(option: PersonaFisicaModel) =>
                  `${option.nome} ${option.cognome} - ${option.codice_fiscale}`
                }
                onChange={(event, newInputValue: PersonaFisicaModel | null) => {
                  setSelectedClienteId(newInputValue?.id_cliente)
                }}
                onInputChange={(event, newInputValue: string) => {
                  setSearchClienti(newInputValue)
                }}
              />
            </Box>
          </>}
        </DialogContent>
        <DialogActions sx={{ paddingLeft: "18pt", paddingRight: "18pt", justifyContent: "space-between" }}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button onClick={() => {
              if (onClose !== undefined) {
                onClose()
              }

              if (!editEnabled) {
                resetFields()
              }

              closeActions()
            }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">{editEnabled ? "Salva" : "Crea"}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BeneFormComponent
