import React, { useState } from "react"
import SideBar from "../../components/sidebar/Sidebar"
import "./Beni.scss"
import AddIcon from "@mui/icons-material/Add"
import { Fab } from "@mui/material"
import BeneMaterialeTableComponent from "../../components/bene/materiale/BeneMaterialeTableComponent"

const BeneMaterialePage: React.FC<{}> = () => {

  const [fabVisible, setFabVisible] = useState(true)

  return (
    <div className="home">
      {fabVisible &&
        <Fab
          onClick={() => setFabVisible(false)}
          sx={{ position: "fixed", bottom: 16, right: 16 }} color="primary" aria-label="add">
          <AddIcon />
        </Fab>
      }
      <SideBar />
      <div className="homeContainer">
        <BeneMaterialeTableComponent
          alterFabVisibility={setFabVisible}
          fabVisible={fabVisible}
          enableCreate={true}
        />
      </div>
    </div>
  )
}

export default BeneMaterialePage
