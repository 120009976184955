let accessToken: string = ""
let refreshAccessToken: string = ""

const setAccessToken = (newAccessToken: string) => {
  accessToken = newAccessToken
}

const getAccessToken = (): string => {
  return accessToken
}

const setRefreshAccessToken = (newRefreshAccessToken: string) => {
  refreshAccessToken = newRefreshAccessToken
}

const getRefreshAccessToken = (): string => {
  return refreshAccessToken
}

export {
  setAccessToken, getAccessToken,
  setRefreshAccessToken, getRefreshAccessToken
}
