import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { WithCUToggleCommandsAndData, WithRecordID, WithData } from "../../../models/CommonModels"
import { Telefono, TelefonoPostModel, TelefonoPutModel, TelefonoRequest } from "../../../models/TelefonoModel"
import { createTelefono, editTelefono, fetchTelefono } from "../../../api/telefoni"
import { fetchSingleTelefonoQueryKey, fetchTelefoniQueryKey } from "../../../utils/QueryClient"
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, TextField, Typography } from "@mui/material"
import { REQUIRED_HELPER_TEXT } from "../../../utils/form"
import { AxiosError } from "axios"
import { fromAxiosErrorToMessage } from "../../../utils/crud"
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent"

const CustomerTelefonoForm: React.FC<WithCUToggleCommandsAndData<Telefono>> = ({ initialData, open, toggleOpen, editEnabled, onClose }) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [telefono, setTelefono] = useState<string>()

  const query = useQuery({
    queryKey: [fetchSingleTelefonoQueryKey, initialData.id],
    queryFn: () => fetchTelefono(initialData.id),
    enabled: !!initialData.id,
    onSuccess: (getResponse) => {
      setTelefono(getResponse.data.numero)
    },
    refetchOnWindowFocus: false,
  })

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createTelefono(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchTelefoniQueryKey)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editTelefono(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchTelefoniQueryKey)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const resetFields = () => {
    // resetting fields
    setTelefono("")
  }

  if (query.isFetching || query.isLoading)
    return (<Skeleton animation="wave" />)

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchTelefoniQueryKey)
    }
  }

  const handleSubmit = () => {
    if (telefono === undefined) {
      return
    }

    if (editEnabled) {
      const telefonoTemplate = {} as TelefonoPutModel
      const telefonoData = {} as TelefonoRequest
      const postData = {} as WithRecordID
      telefonoTemplate.numero = telefono
      telefonoData.telefonocliente = telefonoTemplate
      postData.data = telefonoData
      postData.recordID = initialData.id

      editMutation.mutate(postData)
    } else {
      const telefonoPost = {} as TelefonoPostModel
      telefonoPost.id_cliente = initialData.id_cliente
      telefonoPost.numero = telefono
      const postData = {} as WithData
      const body = {} as TelefonoRequest
      body.telefonocliente = telefonoPost
      postData.data = body

      createMutation.mutate(postData)
    }

    resetFields()
    toggleOpen()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>{editEnabled ? "Modifica" : "Crea Nuovo"} Telefono Cliente</DialogTitle>
        <DialogContent style={{ width: 500 }}>

          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => { setHideError(true) }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>{REQUIRED_HELPER_TEXT}</Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Numero"
            name="numero"
            size="small"
            value={telefono}
            onChange={e => setTelefono(e.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ paddingLeft: "18pt", paddingRight: "18pt", justifyContent: "space-between" }}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button onClick={() => {
              if (onClose !== undefined) {
                onClose()
              }

              if (!editEnabled) {
                resetFields()
              }
              closeActions()
            }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">{editEnabled ? "Salva" : "Aggiungi"}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomerTelefonoForm
