import { AxiosResponse } from "axios"
import { NomeDescrizione } from "../models/StatoTipologiaModel"

export const REQUIRED_HELPER_TEXT = "* Campi Obblligatori"

export const appendNullOption = (data: NomeDescrizione[]) => {
  let tmp = data
  tmp.unshift({
    id: 0,
    nome: "Non disponibile"
  })
  return tmp
}
