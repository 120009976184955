import { Typography } from "@mui/material"

interface SectionTitleProps {
  title: string,
}

const SectionTitle: React.FC<SectionTitleProps> = ({ title }) => {
  return (<>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{title}</Typography>
  </>)
}

export default SectionTitle
