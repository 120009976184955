import { AxiosError } from "axios"
import { WithRecordID, WithData } from "../models/CommonModels"

export const prepareCreateData = (data: any): WithData => {

  const postData = {} as WithData
  postData.data = data

  return postData
}

export const prepareEditData = (id: number, data: any): WithRecordID => {

  const postData = {} as WithRecordID
  postData.recordID = id
  postData.data = data

  return postData
}

export const fromAxiosErrorToMessage = (err: AxiosError): string => {
  let error = err.response?.data
  return (error as Error).message || "Errore non previsto, contattare assistenza"
}
