
import { api_url } from "../utils/ApiContext"
import { WithRecordID, WithData } from "../models/CommonModels"
import { Indirizzo } from "../models/IndirizzoModel"
import { deleteRequest, getRequest, postRequest, putRequest } from "./apiCommon"

const fetchCustomerIndirizzi = (id_cliente: number) => {
  return getRequest<Indirizzo[]>
    (api_url.concat("/api/indirizzocliente?id_cliente=", id_cliente.toString()))
}

const fetchIndirizzo = (id: number) => {
  return getRequest<Indirizzo>
    (api_url.concat("/api/indirizzocliente/", id.toString()))
}

const createIndirizzo = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/indirizzocliente"), postdata.data)
}

const deleteIndirizzo = (postdata: WithData) => {
  return deleteRequest(api_url.concat("/api/indirizzocliente/", postdata.data as string))
}

const editIndirizzo = (putdata: WithRecordID) => {
  return putRequest(api_url.concat("/api/indirizzocliente/", putdata.recordID.toString()),
    putdata.data)
}

export { fetchCustomerIndirizzi, fetchIndirizzo, createIndirizzo, deleteIndirizzo, editIndirizzo }
