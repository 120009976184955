import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Skeleton, TextField, Typography } from "@mui/material"
import { WithCUToggleCommandsAndData, WithRecordID, WithData } from "../../../models/CommonModels"
import { CredenzialiPortaleVenditaModel, CredenzialiPortaleVenditaPostModel, CredenzialiPortaleVenditaPutModel, CredenzialiPortaleVenditaRequest } from "../../../models/CredenzialiPortaleVenditaModel"
import { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { createCredenzialiPortaleVendita, editCredenzialiPortaleVendita, fetchCustomerCredenzialePortaleVendita } from "../../../api/credenzialiportalevendita"
import { fetchCustomerCredenzialiPortaleVenditaQueryKey, fetchAnagraficaPortaleQueryKey } from "../../../utils/QueryClient"
import { REQUIRED_HELPER_TEXT } from "../../../utils/form"
import { fetchAnagraficaPortale } from "../../../api/anagraficaportale"
import StatoTipologiaSelectComponent from "../../shared/StatoTipologiaSelectComponent"
import { NomeDescrizione } from "../../../models/StatoTipologiaModel"
import { AxiosError } from "axios"
import { fromAxiosErrorToMessage } from "../../../utils/crud"
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent"

const PortaleVenditaForm: React.FC<WithCUToggleCommandsAndData<CredenzialiPortaleVenditaModel>> = ({ initialData, open, toggleOpen, editEnabled, onClose }) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [credenzialiAccesso, setCredenzialiAccesso] = useState<string>()
  const [selectedAnagrafica, setSelectedAnagrafica] = useState<number>(-1)

  const [anagraficaPortaleRecords, setAnagraficaPortaleRecords] = useState<NomeDescrizione[]>(undefined as any)

  const anagraficaPortaleQuery = useQuery({
    queryKey: [fetchAnagraficaPortaleQueryKey],
    queryFn: () => fetchAnagraficaPortale(initialData.id_cliente),
    onSuccess: (getResponse) => {
      setAnagraficaPortaleRecords(getResponse.data.map(d => {
        return { id: d.id, nome: d.nome_portale, descrizione: d.nome_portale } as NomeDescrizione
      }))
      let firstElement = getResponse.data[0]
      if (firstElement !== undefined) {
        setSelectedAnagrafica(firstElement.id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const query = useQuery({
    queryKey: [fetchCustomerCredenzialePortaleVendita, initialData.id],
    enabled: !!initialData.id,
    queryFn: () => fetchCustomerCredenzialePortaleVendita(initialData.id),
    onSuccess: (getResponse) => {
      setCredenzialiAccesso(getResponse.data.credenziali_di_accesso)
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const createMutation = useMutation({
    mutationFn: (x: WithData) => createCredenzialiPortaleVendita(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCustomerCredenzialiPortaleVenditaQueryKey)
      queryClient.invalidateQueries(fetchAnagraficaPortaleQueryKey)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editCredenzialiPortaleVendita(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchCustomerCredenzialiPortaleVenditaQueryKey)
      queryClient.invalidateQueries(fetchAnagraficaPortaleQueryKey)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const resetFields = () => {
    // resetting fields
    setSelectedAnagrafica(11)
  }

  if (query.isFetching || query.isLoading || anagraficaPortaleQuery.isFetching || anagraficaPortaleQuery.isLoading)
    return (<Skeleton animation="wave" />)

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchCustomerCredenzialiPortaleVenditaQueryKey)
      queryClient.invalidateQueries(fetchAnagraficaPortaleQueryKey)
    }
  }

  const handleSubmit = () => {
    if (selectedAnagrafica === undefined || credenzialiAccesso === undefined) {
      return
    }

    if (editEnabled) {
      const crpv = {} as CredenzialiPortaleVenditaPutModel
      crpv.credenziali_di_accesso = credenzialiAccesso
      crpv.id_anagrafica_portale = initialData.id_anagrafica_portale

      const crpvRequest = {} as CredenzialiPortaleVenditaRequest
      const putData = {} as WithRecordID
      crpvRequest.credenzialiportalevendita = crpv
      putData.data = crpvRequest
      putData.recordID = initialData.id

      editMutation.mutate(putData)
    } else {
      const crpv = {} as CredenzialiPortaleVenditaPostModel
      crpv.credenziali_di_accesso = credenzialiAccesso

      crpv.id_anagrafica_portale = selectedAnagrafica

      crpv.id_cliente = initialData.id_cliente
      const postData = {} as WithData
      const body = {} as CredenzialiPortaleVenditaRequest
      body.credenzialiportalevendita = crpv
      postData.data = body

      createMutation.mutate(postData)
    }

    resetFields()
    closeActions()
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={closeActions}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
          },
        }}
      >
        <DialogTitle>{editEnabled ? "Modifica" : "Crea Nuova"} Credenziale Portale Vendita</DialogTitle>
        <DialogContent style={{ width: 500 }}>

          <ErrorMessageAlertComponent
            error_message={errorMessage}
            hide={() => { setHideError(true) }}
            visible={!hideError}
          />

          <Typography sx={{ fontSize: "12px", color: "grey" }}>{REQUIRED_HELPER_TEXT}</Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            label="Credenziali di Accesso"
            name="credenziali"
            autoFocus
            multiline
            rows={4}
            value={credenzialiAccesso}
            onChange={e => setCredenzialiAccesso(e.target.value)}
          />

          {!editEnabled && <>
            <br /> <br />
            <Box display="flex" flexDirection="column" justifyContent="center">
              {anagraficaPortaleRecords.length > 0 ? (
                <StatoTipologiaSelectComponent
                  initialId={selectedAnagrafica}
                  disabled={false}
                  label="Materiale dell' Articolo"
                  records={anagraficaPortaleRecords}
                  setId={setSelectedAnagrafica}
                />
              ) : (<Skeleton animation="wave" />)}
              <br />
            </Box>
          </>
          }
        </DialogContent>
        <DialogActions sx={{ paddingLeft: "18pt", paddingRight: "18pt", justifyContent: "space-between" }}>
          <Box sx={{ justifyContent: "flex-start" }}>
            <Button onClick={() => {
              if (onClose !== undefined) {
                onClose()
              }

              if (!editEnabled) {
                resetFields()
              }
              toggleOpen()
            }}
            >
              Chiudi
            </Button>
          </Box>
          <Box sx={{ justifyContent: "flex-end" }}>
            <Button onClick={() => handleSubmit()} variant="contained">{editEnabled ? "Salva" : "Aggiungi"}</Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default PortaleVenditaForm
