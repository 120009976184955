import { api_url } from "../utils/ApiContext"
import { ContrattoDetailed, ContrattoTableModel } from "../models/ContrattoModel"
import { FetchPaginatedApiModel, WithRecordID, WithData } from "../models/CommonModels"
import { deleteRequest, getRequest, postRequest, putRequest } from "./apiCommon"
import { GridFilterModel } from "@mui/x-data-grid"
import { SortingModel } from "../models/shared/SortingModel"
import { appendStringFilterToUri, appendNumberFilterToUri } from "../utils/filtering"

const TAKE_LIMIT = 15

const setQueryParameters = (filter: GridFilterModel | undefined, sort: SortingModel, id_anagrafica_cliente: number) => {

  let uri = api_url.concat("/api/contratto?")

  filter?.items.forEach(item => {
    uri = appendStringFilterToUri(item.field, "titolo", item.value, uri);
    uri = appendStringFilterToUri(item.field, "quantita", item.value, uri);
    uri = appendStringFilterToUri(item.field, "importo", item.value, uri);
    uri = appendStringFilterToUri(item.field, "id_stato_contratto", item.value, uri);
    uri = appendStringFilterToUri(item.field, "id_tipologia_contratto", item.value, uri);
  })

  if (id_anagrafica_cliente !== -1) {
    uri = appendNumberFilterToUri("id_cliente", "id_cliente", id_anagrafica_cliente, uri)
  }

  uri = uri.concat("&sort_field=", sort.field.toString())
  uri = uri.concat("&sort_direction=", sort.direction.toString())

  uri = uri.endsWith("?") ? uri.concat("skip=") : uri.concat("&skip=")

  return uri
}

const fetchSelectContratti = (page: number, filter: GridFilterModel | undefined, sort: SortingModel, id_anagrafica_cliente: number) => {
  let uri = setQueryParameters(filter, sort, id_anagrafica_cliente)
  return fetchContratti(page, uri)
}

const fetchContratti = (page: number, uri: string = api_url.concat("/api/contratto?skip=")) => {
  let skip = 0
  let take = TAKE_LIMIT
  if (page > 0) skip = (page) * TAKE_LIMIT

  return getRequest<FetchPaginatedApiModel<ContrattoTableModel[]>>
    (uri.concat(skip.toString(), "&take=", take.toString()))
}

const fetchContratto = (getData: WithData) => {
  return getRequest<ContrattoDetailed>
    (api_url.concat("/api/contratto/", getData.data as string))
}

const createContratto = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/contratto"), postdata.data)
}

const editContratto = (putdata: WithRecordID) => {
  return putRequest(api_url.concat("/api/contratto/", putdata.recordID.toString()),
    putdata.data)
}

const deleteContratto = (postdata: WithData) => {
  return deleteRequest(api_url.concat("/api/contratto/", postdata.data as string))
}

export {
  deleteContratto,
  fetchContratti,
  createContratto,
  fetchContratto,
  editContratto,
  fetchSelectContratti
}
