import { GridFilterModel } from "@mui/x-data-grid";
import { SortingModel } from "../../models/shared/SortingModel";
import { api_url } from "../../utils/ApiContext";
import { appendNumberFilterToUri, appendStringFilterToUri } from "../../utils/filtering";
import { BeneSottoTipologiaModel } from "../../models/api/response/bene/BeneSottoTipologiaModel";
import { FetchPaginatedApiModel, WithData, WithRecordID } from "../../models/CommonModels";
import { deleteRequest, getRequest, postRequest, putRequest } from "../apiCommon";

const TAKE_LIMIT = 15

const setQueryParameters = (filter: GridFilterModel | undefined, sort: SortingModel, id_tipologia_bene: number) => {

  let uri = api_url.concat("/api/sottotipologiabene?")

  filter?.items.forEach(item => {
    uri = appendStringFilterToUri(item.field, "nome", item.value, uri);
    uri = appendStringFilterToUri(item.field, "descrizione", item.value, uri);
  })

  if (id_tipologia_bene !== -1) {
    uri = appendNumberFilterToUri("id_tipologia_bene", "id_tipologia_bene", id_tipologia_bene, uri)
  }

  uri = uri.concat("&sort_field=", sort.field.toString())
  uri = uri.concat("&sort_direction=", sort.direction.toString())

  uri = uri.endsWith("?") ? uri.concat("skip=") : uri.concat("&skip=")

  return uri
}

const getSottotipologie = (selectedId: number, page: number, sort: SortingModel, id_tipologia_bene: number, filter?: GridFilterModel) => {
  let uri = setQueryParameters(filter, sort, id_tipologia_bene)

  let skip = 0
  let take = TAKE_LIMIT
  if (page > 0) skip = (page) * take

  return getRequest<FetchPaginatedApiModel<BeneSottoTipologiaModel[]>>(
    uri.concat('&skip=', skip.toString(), '&take=', take.toString(), '&selectedId=', selectedId.toString())
  );
}

const getSottotipologia = (id: string) => {
  return getRequest<BeneSottoTipologiaModel>
    (api_url.concat("/api/sottotipologiabene/", id))
}

const createSottoTipologiaBene = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/sottotipologiabene"), postdata.data)
}

const updateSottoTipologia = (putdata: WithRecordID) => {
  return putRequest(api_url.concat("/api/sottotipologiabene/", putdata.recordID.toString()), putdata.data)
}

const deleteSottoTipologia = (id: number) => {
  return deleteRequest(api_url.concat("/api/sottotipologiabene/", id.toString()))
}

export {
  getSottotipologie,
  getSottotipologia,
  createSottoTipologiaBene,
  updateSottoTipologia,
  deleteSottoTipologia
}
