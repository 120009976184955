import { useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import VisibilityIcon from "@mui/icons-material/Visibility"
import { DataGrid, GridRenderCellParams, itIT } from "@mui/x-data-grid"
import { WithDataTableProps } from "../../models/CommonModels"
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent"
import { Box, IconButton, Tooltip } from "@mui/material"

// TODO: removed useless 'V' parameter
const FilteredDataTable = <T, V>({
  detailsOperations,
  editOperations,
  deleteOperations,
  onFilterChange,
  onSortChange,
  errorMessage,
  hideError,
  rows,
  columns,
  filters,
  sortModel,
  rowCount,
  onPaginationModelChange
}: WithDataTableProps<T, V>) => {

  const [page] = useState(1)

  let tableColumns = [
    {
      field: "delete_action",
      // flex: 1,
      maxWidth: 10,
      headerName: "",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (record: GridRenderCellParams<any, T>) => (
        <>
          {deleteOperations !== undefined ?
            <Tooltip title="Cancella">
              <IconButton className="action_icon" onClick={() => {
                deleteOperations(record)
              }}>
                <DeleteForeverIcon color="error" />
              </IconButton>
            </Tooltip> : <></>
          }
        </>
      ),
    },
    ...columns
  ]

  let actionsColumn = {
    field: "actions",
    // flex: 1,
    // minWidth: 200,
    headerName: "Azioni",
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (record: GridRenderCellParams<any, T>) => (
      <>
        {detailsOperations !== undefined ?
          <Tooltip title="Dettagli">
            <IconButton className="action_icon" onClick={() => {
              detailsOperations(record.row)
            }
            }>
              <VisibilityIcon color="secondary" />
            </IconButton>
          </Tooltip> : <></>
        }

        {editOperations !== undefined ?
          <Tooltip title="Modifica">
            <IconButton className="action_icon" onClick={() => {
              editOperations(record)
            }}>
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip> : <></>
        }
      </>
    ),
  }

  tableColumns = (detailsOperations === undefined && editOperations === undefined)
    ? [...tableColumns]
    : [...tableColumns, actionsColumn]

  return (
    <>
      <Box>
        <ErrorMessageAlertComponent error_message={errorMessage} hide={hideError} visible={errorMessage.length > 0} />
        <DataGrid
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          sx={{
            display: "flex",
            flexDirection: "column-reverse"
          }}
          onCellDoubleClick={
            (params) => {
              if (detailsOperations !== undefined) {
                detailsOperations(params.row)
              }
            }}
          filterModel={filters}
          onFilterModelChange={onFilterChange}
          sortingMode="server"
          paginationMode="server"
          onPaginationModelChange={onPaginationModelChange}
          rowCount={rowCount}
          sortModel={sortModel}
          onSortModelChange={onSortChange}
          rows={rows}
          autoHeight={true}
          columns={
            tableColumns
          }
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 15, // TODO: maybe generalize
              },
            },
          }}
          pageSizeOptions={[page]}
          disableRowSelectionOnClick
          pagination
          slotProps={{
            filterPanel: {
              filterFormProps: {
                operatorInputProps: {
                  disabled: true,
                },
              }
            }
          }}
        />
      </Box>
    </>
  )
}

export default FilteredDataTable
