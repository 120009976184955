import dayjs from "dayjs"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc)
dayjs.extend(timezone)

const calcolaDataDiNascita = (codice_fiscale: string | undefined) => {

  if (codice_fiscale === undefined) {
    console.error('codice_fiscale undefined')
    return null
  }

  const map = new Map<string, string>()
  map.set("A", "01")
  map.set("B", "02")
  map.set("C", "03")
  map.set("D", "04")
  map.set("E", "05")
  map.set("H", "06")
  map.set("L", "07")
  map.set("M", "08")
  map.set("P", "09")
  map.set("R", "10")
  map.set("S", "11")
  map.set("T", "12")

  if (codice_fiscale.length !== 16) return null

  let year = codice_fiscale.substring(6, 8)
  let month = codice_fiscale[8]
  if (!map.has(month)) return null
  const day = codice_fiscale.substring(9, 11)
  month = map.get(month)!!
  console.log(year.concat("-", month, "-", day))
  return year.concat("-", month, "-", day)
}

const displayFormatted = (date: string | undefined) => {

  if (date === undefined) {
    return ""
  }

  let parsedDate = dayjs(date)
  if (parsedDate.isValid()) {
    return parsedDate.format("DD/MM/YYYY").toString()
  }

  return "Non disponibile"
}

const displayFormattedWithTime = (date: string | undefined) => {

  if (date === undefined) {
    return ""
  }

  let parsedDate = dayjs(date).tz("UTC", true)
  if (parsedDate.isValid()) {
    return parsedDate.tz("Europe/Rome").format("DD/MM/YYYY HH:mm:ss").toString()
  }

  return "Non disponibile"
}

export {
  calcolaDataDiNascita,
  displayFormatted,
  displayFormattedWithTime
}
