import React from "react"
import { BeneModelDetailed } from "../../models/BeneModel"
import { Grid } from "@mui/material"
import DisplayField from "../shared/DisplayField"

interface VenditaBeneContrattoComponentProps {
  bene: BeneModelDetailed
}

const VenditaBeneContrattoComponent: React.FC<VenditaBeneContrattoComponentProps> = ({ bene }) => {
  return (
    <Grid container rowSpacing={1} sx={{ padding: "20px" }}>
      <DisplayField fill={3} title="Totale Vendita" text={("" + bene.benecontratto[0].venditabenecontratto?.totale_lordo || "0") + " €"} />
      <DisplayField fill={3} title="Trattenute %" text={("" + bene.benecontratto[0].venditabenecontratto?.percentuale_trattenute_oc || "0") + " %"} />
      <DisplayField fill={3} title="Dare" text={("" + bene.benecontratto[0].venditabenecontratto?.avere || "0") + " €"} />
      <DisplayField fill={3} title="Avere" text={("" + bene.benecontratto[0].venditabenecontratto?.avere || "0") + " €"} />
    </Grid>
  )
}

export default VenditaBeneContrattoComponent

