import { useNavigate } from "react-router-dom"
import { FetchPaginatedApiModel, WithFabObj } from "../../../models/CommonModels"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useState } from "react"
import React from "react"
import { BeneTipologiaModel } from "../../../models/api/response/bene/BeneTipologiaModel"
import { GridColDef, GridFilterModel, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid"
import { SortingModel } from "../../../models/shared/SortingModel"
import { fetchTipologiaBeneSelectQueryKey } from "../../../utils/QueryClient"
import { deleteTipologia, getTipologie } from "../../../api/bene/tipologia"
import { castToBackendSort } from "../../../utils/sorting"
import DeleteDialog from "../../shared/DeleteDialog"
import FilteredDataTable from "../../shared/FilteredDataTable"
import BeneTipologiaFormComponent from "./BeneTipologiaFormComponent"

export interface BeneTipologiaComponentProps extends WithFabObj {
  enableCreate: boolean
}

const BeneTipologiaTableComponent: React.FC<BeneTipologiaComponentProps> = ({
  alterFabVisibility,
  fabVisible,
  enableCreate
}) => {

  const navigator = useNavigate()
  const queryClient = useQueryClient()

  const [editFormOpen, setEditFormOpen] = useState<boolean>(false)
  const toggleEditFormOpen = () => setEditFormOpen(!editFormOpen)

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen)

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const [tipologie, setTipologie] = useState<FetchPaginatedApiModel<BeneTipologiaModel[]>>(undefined as any)
  const [selectedRowElement, setSelectedRowElement] = useState<BeneTipologiaModel>()

  const [filters, setFilters] = useState<GridFilterModel | undefined>()
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [errorMessage, setErrorMessage] = useState("")
  const hideError = () => setErrorMessage("")

  // query
  useQuery(
    [fetchTipologiaBeneSelectQueryKey, paginationModel.page, filters, sortBy, sortDirection],
    () => {
      const sor = {} as SortingModel
      sor.field = sortBy || "id"
      sor.direction = sortDirection || "DESC"
      return getTipologie(0, paginationModel.page, sor, filters)
    },
    {
      keepPreviousData: true, onSuccess: (getResponse) => {
        setTipologie(getResponse.data)
      }, refetchOnWindowFocus: false
    })

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteTipologia(id),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchTipologiaBeneSelectQueryKey)
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined) setErrorMessage(e.response.data.message)
      else setErrorMessage("Errore non previsto, riprovare o richiedere assistenza")
    }
  })

  const editOperations = (record: GridRenderCellParams<any, BeneTipologiaModel>) => {
    setSelectedRowElement(record.value)
    toggleEditFormOpen()
  }

  const detailsOperations = (record: GridRenderCellParams<any, BeneTipologiaModel>) => {
    if (record.id !== undefined) {
      let model = (record as any).actions as BeneTipologiaModel
      navigator("/bene/tipologia/".concat(model.id.toString()))
    }
  }

  const deleteOperations = (record: GridRenderCellParams<any, BeneTipologiaModel>) => {
    setSelectedRowElement(record.value)
    toggleDeleteAlert()
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Tipologia",
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      flex: 1,
    },
  ];

  return (
    <>
      <FilteredDataTable<BeneTipologiaModel, {}>
        editOperations={editOperations}
        deleteOperations={deleteOperations}
        detailsOperations={detailsOperations}
        rows={
          (tipologie?.data !== undefined) ? tipologie.data.map(d => {
            return {
              id: d.id,
              nome: d.nome,
              descrizione: d.descrizione,
              actions: d,
              delete_action: d
            }
          }) : []
        }
        rowCount={tipologie?.count}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        errorMessage={errorMessage}
        hideError={hideError}
        filters={filters}
        sortModel={sortModel}
        onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
        onSortChange={(newSortModel) => {
          newSortModel.forEach(item => {
            if (item !== undefined) {
              setSortBy(item.field)
              setSortDirection(castToBackendSort(item.sort))
            }
          })

          setSortModel(newSortModel)
        }}
      />

      {/* Create */}
      <BeneTipologiaFormComponent
        initialData={{} as BeneTipologiaModel}
        open={enableCreate ? !fabVisible : false}
        toggleOpen={() => { }}
        onClose={() => alterFabVisibility(true)}
      />

      {/* Edit */}
      {
        (selectedRowElement !== undefined) && <BeneTipologiaFormComponent
          initialData={selectedRowElement}
          open={editFormOpen}
          toggleOpen={() => {
            toggleEditFormOpen()
          }}
          onClose={() => {
            setSelectedRowElement(undefined)
            toggleEditFormOpen()
          }}
          editEnabled={true}
        />
      }

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.id}
        onPositive={
          () => {
            toggleDeleteAlert()
            if (selectedRowElement?.id) {
              deleteMutation.mutate(selectedRowElement.id)
            }
          }
        }
        onNegative={toggleDeleteAlert}
      />
    </>
  )
}

export default BeneTipologiaTableComponent
