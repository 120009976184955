import { FetchPaginatedApiModel, WithFabObj } from "../../../models/CommonModels"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { GridColDef, GridFilterModel, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid"
import { useState } from "react"
import { BeneSottoTipologiaModel } from "../../../models/api/response/bene/BeneSottoTipologiaModel"
import { deleteSottoTipologia, getSottotipologie } from "../../../api/bene/sottotipologia"
import { SortingModel } from "../../../models/shared/SortingModel"
import { fetchSottoTipologiaBeneSelectQueryKey } from "../../../utils/QueryClient"
import FilteredDataTable from "../../shared/FilteredDataTable"
import { castToBackendSort } from "../../../utils/sorting"
import BeneSottoTipologiaFormComponent from "./BeneSottoTipologiaFormComponent"
import DeleteDialog from "../../shared/DeleteDialog"

export interface BeneSottoTipologiaComponentProps extends WithFabObj {
  enableCreate: boolean,
  id_tipologia_bene: number
}

const BeneSottoTipologiaTableComponent: React.FC<BeneSottoTipologiaComponentProps> = ({
  alterFabVisibility,
  fabVisible,
  enableCreate,
  id_tipologia_bene
}) => {

  const queryClient = useQueryClient()

  const [editFormOpen, setEditFormOpen] = useState<boolean>(false)
  const toggleEditFormOpen = () => setEditFormOpen(!editFormOpen)

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen)

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const [sottoTipologie, setSottoTipologie] = useState<FetchPaginatedApiModel<BeneSottoTipologiaModel[]>>(undefined as any)
  const [selectedRowElement, setSelectedRowElement] = useState<BeneSottoTipologiaModel>()

  const [filters, setFilters] = useState<GridFilterModel | undefined>()
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [errorMessage, setErrorMessage] = useState("")
  const hideError = () => setErrorMessage("")

  // query
  useQuery(
    [fetchSottoTipologiaBeneSelectQueryKey, paginationModel.page, filters, sortBy, sortDirection],
    () => {
      const sor = {} as SortingModel
      sor.field = sortBy || "id"
      sor.direction = sortDirection || "DESC"
      return getSottotipologie(0, paginationModel.page, sor, id_tipologia_bene, filters)
    },
    {
      keepPreviousData: true, onSuccess: (getResponse) => {
        setSottoTipologie(getResponse.data)
      }, refetchOnWindowFocus: false
    })

  const deleteMutation = useMutation({
    mutationFn: (id: number) => deleteSottoTipologia(id),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchSottoTipologiaBeneSelectQueryKey)
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined) setErrorMessage(e.response.data.message)
      else setErrorMessage("Errore non previsto, riprovare o richiedere assistenza")
    }
  })

  const editOperations = (record: GridRenderCellParams<any, BeneSottoTipologiaModel>) => {
    setSelectedRowElement(record.value)
    toggleEditFormOpen()
  }

  const deleteOperations = (record: GridRenderCellParams<any, BeneSottoTipologiaModel>) => {
    setSelectedRowElement(record.value)
    toggleDeleteAlert()
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Sotto-Tipologia",
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      flex: 1,
    },
  ];

  return (
    <>
      <FilteredDataTable<BeneSottoTipologiaModel, {}>
        editOperations={editOperations}
        deleteOperations={deleteOperations}
        rows={
          (sottoTipologie?.data !== undefined) ? sottoTipologie.data.map(d => {
            return {
              id: d.id,
              nome: d.nome,
              descrizione: d.descrizione,
              actions: d,
              delete_action: d
            }
          }) : []
        }
        rowCount={sottoTipologie?.count}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        errorMessage={errorMessage}
        hideError={hideError}
        filters={filters}
        sortModel={sortModel}
        onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
        onSortChange={(newSortModel) => {
          newSortModel.forEach(item => {
            if (item !== undefined) {
              setSortBy(item.field)
              setSortDirection(castToBackendSort(item.sort))
            }
          })

          setSortModel(newSortModel)
        }}
      />

      {/* Create */}
      <BeneSottoTipologiaFormComponent
        initialData={{ id_tipologia_bene: id_tipologia_bene } as BeneSottoTipologiaModel}
        open={enableCreate ? !fabVisible : false}
        toggleOpen={() => { }}
        onClose={() => alterFabVisibility(true)}
      />

      {/* Edit */}
      {
        (selectedRowElement !== undefined) && <BeneSottoTipologiaFormComponent
          initialData={selectedRowElement}
          open={editFormOpen}
          toggleOpen={() => {
            toggleEditFormOpen()
          }}
          onClose={() => {
            setSelectedRowElement(undefined)
            toggleEditFormOpen()
          }}
          editEnabled={true}
        />
      }

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.id}
        onPositive={
          () => {
            toggleDeleteAlert()
            if (selectedRowElement?.id) {
              deleteMutation.mutate(selectedRowElement.id)
            }
          }
        }
        onNegative={toggleDeleteAlert}
      />
    </>
  )
}

export default BeneSottoTipologiaTableComponent
