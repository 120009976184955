import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material"

interface DeleteDialogProps {
  open: boolean,
  onClose: () => void,
  identifier: string | number | undefined,
  onPositive: () => void,
  onNegative: () => void,
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({ open, onClose, identifier, onPositive, onNegative }) => {
  return (
    <>
      {/* Delete */}
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confermare cancellazione di " + identifier + "?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={onNegative}>No</Button>
          <Button onClick={onPositive} autoFocus>
            Si
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default DeleteDialog
