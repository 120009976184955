import { Typography, IconButton, TextField, Grid, Stack } from "@mui/material"
import React, { useState } from "react"
import { useQueryClient, useMutation } from "react-query"
import { editPG } from "../../../api/personagiuridica"
import { GenesiAnagraficaCliente } from "../../../models/AnagraficaClienteModel"
import { WithRecordID } from "../../../models/CommonModels"
import { PersonaGiuridicaModel, PGPut, PersonaGiuridicaPostPutModel } from "../../../models/PersonaGiuridicaModel"
import { fetchPGQueryKey } from "../../../utils/QueryClient"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import EditIcon from "@mui/icons-material/Edit"
import DoneIcon from "@mui/icons-material/Done"
import ErrorMessageAlertComponent from "../../errors/ErrorMessageAlertComponent"

interface WithPG {
  pg: PersonaGiuridicaModel
}

const PersonaGiuridicaDetailComponent: React.FC<WithPG> = ({ pg }) => {
  const queryClient = useQueryClient()
  const [error, setError] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [ragioneSociale, setRagioneSociale] = useState(pg.ragione_sociale)
  const [partitaIva, setPartitaIva] = useState(pg.partita_iva)
  const [codicefiscale, setCodicefiscale] = useState(pg.codice_fiscale)
  const [sdi, setSdi] = useState(pg.SDI)
  const [genesiCliente, setGenesiCliente] = useState(pg.anagraficacliente.genesi_cliente)
  const [errorMessage, setErrorMessage] = useState("")
  const hideError = () => setErrorMessage("")

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editPG(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchPGQueryKey)
      setIsEditing(!isEditing)
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined) setErrorMessage(e.response.data.message)
      else setErrorMessage("Errore non previsto, riprovare o richiedere assistenza")
      setError(true)
    }
  })

  return (
    <>
      <ErrorMessageAlertComponent hide={hideError} visible={errorMessage.length > 0} error_message={errorMessage} />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ padding: "20px" }}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={2}>
            <Typography variant="h5" gutterBottom>
              Dati Anagrafici
            </Typography>
            <IconButton className="action_icon" onClick={() => {
              if (isEditing) {
                const pgPut = {} as PGPut
                pgPut.anagraficacliente = {} as GenesiAnagraficaCliente
                pgPut.anagraficacliente.genesi_cliente = genesiCliente
                pgPut.personagiuridica = {} as PersonaGiuridicaPostPutModel
                pgPut.personagiuridica.codice_fiscale = codicefiscale
                pgPut.personagiuridica.ragione_sociale = ragioneSociale
                pgPut.personagiuridica.SDI = sdi
                pgPut.personagiuridica.partita_iva = partitaIva
                const postData = {} as WithRecordID
                postData.recordID = pg.id
                postData.data = pgPut
                editMutation.mutate(postData)
              }
              else setIsEditing(!isEditing)
            }}>
              {
                !isEditing ?
                  (<EditIcon color="primary" />) : (<DoneIcon color="secondary" />)
              }
            </IconButton>
            {
              isEditing &&
              (<IconButton className="action_icon" onClick={() => {
                setRagioneSociale(pg.ragione_sociale)
                setSdi(pg.SDI)
                setCodicefiscale(pg.codice_fiscale)
                setPartitaIva(pg.partita_iva)
                setGenesiCliente(pg.anagraficacliente.genesi_cliente)
                setError(false)
                setIsEditing(!isEditing)
              }}><DeleteForeverIcon color="error" /></IconButton>)
            }
          </Stack>

        </Grid>
        <Grid item xs={4}>
          Ragione Sociale <br />
          {!isEditing ? (
            <Typography variant="h6">{pg.ragione_sociale}</Typography>
          ) :
            <TextField
              error={error ? error : undefined}
              margin="normal"
              required
              label="Ragione Sociale"
              name="RagioneSociale"
              value={ragioneSociale}
              onChange={e => setRagioneSociale(e.target.value)}
            />
          }
        </Grid>
        <Grid item xs={4}>
          Partita IVA <br />
          {!isEditing ? (
            <Typography variant="h6">{pg.partita_iva}</Typography>
          ) :
            <TextField
              error={error ? error : undefined}
              margin="normal"
              required
              label="Partita IVA"
              name="PartitaIva"
              value={partitaIva}
              onChange={e => setPartitaIva(e.target.value)}
            />
          }
        </Grid>
        <Grid item xs={4}>
          Codice Fiscale <br />
          {!isEditing ? (
            <Typography variant="h6">{pg.codice_fiscale}</Typography>
          ) :
            <TextField
              error={error ? error : undefined}
              margin="normal"
              label="Codice Fiscale"
              name="codice_fiscale"
              value={codicefiscale}
              onChange={e => setCodicefiscale(e.target.value)}
            />
          }
        </Grid>
        <Grid item xs={4}>
          Genesi Cliente <br />
          {!isEditing ? (
            <Typography variant="h6">{pg.anagraficacliente.genesi_cliente}</Typography>
          ) :
            <TextField
              error={error ? error : undefined}
              margin="normal"
              required
              label="Genesi Cliente"
              name="genesi_cliente"
              value={genesiCliente}
              onChange={e => setGenesiCliente(e.target.value)}
            />
          }
        </Grid>
        <Grid item xs={4}>
          SDI <br />
          {!isEditing ? (
            <Typography variant="h6">{pg.SDI}</Typography>
          ) :
            <TextField
              error={error ? error : undefined}
              margin="normal"
              required
              label="SDI"
              name="sdi"
              value={sdi}
              onChange={e => setSdi(e.target.value)}
            />
          }
        </Grid>
      </Grid>
    </>
  )
}

export default PersonaGiuridicaDetailComponent
