import { Navigate, useNavigate, useParams } from "react-router-dom"
import { BeneTipologiaModel } from "../../models/api/response/bene/BeneTipologiaModel"
import BeneTipologiaDetailComponent from "../../components/bene/tipologia/BeneTipologiaDetailComponent"
import { Grid, Skeleton } from "@mui/material"
import SideBar from "../../components/sidebar/Sidebar"
import { useQuery } from "react-query"
import { useState } from "react"
import { getTipologia } from "../../api/bene/tipologia"
import { fetchDetailedTipologiaBeneQuery } from "../../utils/QueryClient"

const BeneTipologiaDetailPage: React.FC<{}> = () => {

  const navigator = useNavigate()
  const resourceUrl = '/bene/tipologia'

  const { id } = useParams()
  const [tipologia, setTipologia] = useState<BeneTipologiaModel>(undefined as any)

  if (id === undefined) navigator(resourceUrl, { replace: true })

  const query = useQuery({
    queryKey: [fetchDetailedTipologiaBeneQuery],
    queryFn: () => {
      if (id !== undefined) {
        return getTipologia(id)
      }
    },
    onSuccess: (getResponse) => {
      if (getResponse !== undefined) {
        setTipologia(getResponse.data)
      }
    }, refetchOnWindowFocus: false
  })

  if (id === undefined) return (<Navigate to={resourceUrl} replace />)

  if (query.isError) return (<Navigate to="/" replace />)

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        {
          (query.isFetching || query.isLoading || query.isRefetching) ? (
            <Grid container>
              <Grid item xs={12}>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Grid>
            </Grid>
          ) : <BeneTipologiaDetailComponent tipologia={tipologia} />
        }
      </div>
    </div>
  )
}

export default BeneTipologiaDetailPage
