import { FetchPaginatedApiModel } from "../models/CommonModels"
import { CondizioneModel } from "../models/CondizioneModel"
import { api_url } from "../utils/ApiContext"
import { getRequest } from "./apiCommon"

const fetchAllFinalConditions = () => {
  return getRequest<FetchPaginatedApiModel<CondizioneModel[]>>
    (api_url.concat("/api/condizione"))
}

export { fetchAllFinalConditions }
