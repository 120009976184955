import axios from "axios"
import { api_url } from "../utils/ApiContext"
import { LoginModel, LoginResponseModel } from "../models/AuthModel"
import { getRefreshAccessToken } from "../utils/tokenStore"

const csrfcookie = async () => {
  return axios.get(api_url.concat("/sanctum/csrf-cookie"), { withCredentials: true })
}

const login = async (loginModel: LoginModel) => {
  await csrfcookie()
  return axios.post<LoginResponseModel>(
    api_url + "/api/login",
    loginModel,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": true,
        "X-Requested-With": "XMLHttpRequest"
      },
      withCredentials: true
    }
  )
}

const refreshAccessToken = async () => {
  const refreshToken = getRefreshAccessToken()
  if (refreshToken === "") {
    return
  }
  return axios.post<LoginResponseModel>(
    api_url + "/api/refresh-token",
    {},
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": true,
        "X-Requested-With": "XMLHttpRequest",
        Authorization: "Bearer ".concat(refreshToken)
      },
      withCredentials: true
    }
  )
}

export { login, refreshAccessToken, csrfcookie }
