import { AnagraficaPortaleModel } from "../models/AnagraficaPortaleModel"
import { api_url } from "../utils/ApiContext"
import { getRequest } from "./apiCommon"

const fetchAnagraficaPortale = (id_anagrafica_cliente: number) => {
  return getRequest<AnagraficaPortaleModel[]>
    (api_url.concat('/api/anagraficaportale', '?id_cliente=', id_anagrafica_cliente.toString()))
}

export {
  fetchAnagraficaPortale
}
