export const appendStringFilterToUri = (filter_item: string, filter_name: string, filter_value: any, uri: string) => {
  if (filter_item === filter_name && filter_value.toString().length > 0) {
    if (!uri.endsWith("?")) {
      uri = uri.concat("&" + filter_name + "=", filter_value)
    } else {
      uri = uri.concat(filter_name + "=", filter_value)
    }
  }

  return uri
}

export const appendNumberFilterToUri = (filter_item: string, filter_name: string, filter_value: any, uri: string) => {
  if (filter_item === filter_name) {
    if (!uri.endsWith("?")) {
      uri = uri.concat("&" + filter_name + "=", filter_value)
    } else {
      uri = uri.concat(filter_name + "=", filter_value)
    }
  }

  return uri
}
