import React, { useState } from "react"
import SideBar from "../../components/sidebar/Sidebar"
import { Box, Tab, Tabs } from "@mui/material"
import PersonaFisicaPage from "./PersonaFisicaPage"

interface ClientePageProps {
  tipo_cliente: "PEF" | "PG"
}

const ClientePage: React.FC<ClientePageProps> = ({ tipo_cliente }) => {

  const [visualizzazione, setVisualizzazione] = useState<"PEF" | "PG">(tipo_cliente)
  const handleChange = (_: React.SyntheticEvent, newValue: "PEF" | "PG") => {
    setVisualizzazione(newValue);
  }

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        <Box sx={{ width: "100%", marginBottom: "15px" }}>
          <Tabs
            value={visualizzazione}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="Seleziona Cliente"
          >
            <Tab value="PEF" label="Cliente" />
          </Tabs>
          <PersonaFisicaPage />
        </Box>
      </div>
    </div>
  )
}

export default ClientePage
