import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Grid } from "@mui/material"
import { BeneTipologiaModel } from "../../../models/api/response/bene/BeneTipologiaModel"
import EditIcon from "@mui/icons-material/Edit"
import AddIcon from "@mui/icons-material/Add"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import SectionTitle from "../../shared/SectionTitle"
import DisplayField from "../../shared/DisplayField"
import { useState } from "react"
import BeneTipologiaFormComponent from "./BeneTipologiaFormComponent"
import BeneSottoTipologiaTableComponent from "../sottotipologia/BeneSottoTipologiaTableComponent"

interface BeneTipologiaDetailComponentParams {
  tipologia: BeneTipologiaModel
}

const BeneTipologiaDetailComponent: React.FC<BeneTipologiaDetailComponentParams> = ({ tipologia }) => {

  const [mainDataEditFormOpen, setMainDataEditFormOpen] = useState<boolean>(false)
  const toggleMainDataEditFormOpen = () => setMainDataEditFormOpen(!mainDataEditFormOpen)

  // Sotto-Tipologie
  const [sottoTipologiaCreateFormOpen, setSottoTipologiaCreateFormOpen] = useState<boolean>(false)
  const toggleSottoTipologiaFormOpen = () => setSottoTipologiaCreateFormOpen(!sottoTipologiaCreateFormOpen)

  return (
    <>
      <Box>
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Tipologia Bene - Dati Principali" />
          </AccordionSummary>
          <AccordionDetails>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <DisplayField title="Nome" text={tipologia.nome} />
              <DisplayField title="Descrizione" text={tipologia.descrizione} />
            </Grid>
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => {
              toggleMainDataEditFormOpen()
            }}>Modifica</Button>
          </AccordionActions>
        </Accordion >

        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <SectionTitle title="Sotto-Tipologie" />
          </AccordionSummary>
          <AccordionDetails>
            <BeneSottoTipologiaTableComponent
              id_tipologia_bene={tipologia.id}
              enableCreate={sottoTipologiaCreateFormOpen}
              fabVisible={false}
              alterFabVisibility={() => toggleSottoTipologiaFormOpen()}
            />
          </AccordionDetails>
          <AccordionActions>
            <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => { toggleSottoTipologiaFormOpen() }}>Aggiungi</Button>
          </AccordionActions>
        </Accordion>

      </Box >

      {/* Edit */}
      <BeneTipologiaFormComponent
        initialData={tipologia}
        open={mainDataEditFormOpen}
        toggleOpen={toggleMainDataEditFormOpen}
        editEnabled={true}
      />
    </>
  )
}

export default BeneTipologiaDetailComponent
