import { Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"

interface DisplayFieldProps {
  title: string,
  text?: string,
  fill?: number,
  link?: boolean
}

const DisplayField: React.FC<DisplayFieldProps> = ({ title, text, fill, link }) => {
  return (<>
    <Grid item xs={fill || 4} sm={fill || 4} md={fill || 4}>
      <Typography sx={{ fontSize: "12px", }} variant="subtitle2">{title}</Typography>
      {
        link !== undefined
          ? <Typography component={Link} to={text || "#"} sx={{ wordWrap: "break-word" }} variant="h6">{text || ""}</Typography>
          : <Typography sx={{ wordWrap: "break-word" }} variant="h6">{text || ""}</Typography>
      }
    </Grid>
  </>)
}

export default DisplayField
