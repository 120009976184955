import { useState } from "react"
import { useQuery, useQueryClient, useMutation } from "react-query"
import { Navigate, useNavigate } from "react-router-dom"
import { fetchSelectAllPF, deletePF } from "../../../api/personafisica"
import { WithFabObj, FetchPaginatedApiModel, WithData } from "../../../models/CommonModels"
import { PersonaFisicaModel, PersonaFisicaDisplayModel } from "../../../models/PersonaFisicaModel"
import { fetchPFsQueryKey } from "../../../utils/QueryClient"
import { GridColDef, GridFilterModel, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import { SortingModel } from "../../../models/shared/SortingModel"
import { castToBackendSort } from "../../../utils/sorting"
import PersonaFisicaFormComponent from "./PersonaFisicaFormComponent"
import FilteredDataTable from "../../shared/FilteredDataTable"
import DeleteDialog from "../../shared/DeleteDialog"

const PersonaFisicaTableComponent: React.FC<WithFabObj> = ({ fabVisible, alterFabVisibility }) => {

  const navigator = useNavigate()
  const queryClient = useQueryClient()

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen)

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  })
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const [pfResult, setPfResult] = useState<FetchPaginatedApiModel<PersonaFisicaModel[]>>(undefined as any)
  const [selectedRowElement, setSelectedRowElement] = useState<PersonaFisicaModel>()

  const [nome] = useState("")
  const [cognome] = useState("")
  const [codiceFiscale] = useState("")
  const [dataNascita] = useState<Date | undefined>()
  const [genesis] = useState("")

  // const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const hideError = () => setErrorMessage("")

  const [filters, setFilters] = useState<GridFilterModel | undefined>()
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  // backend fetch request
  const query = useQuery([
    fetchPFsQueryKey,
    paginationModel,
    sortBy,
    sortDirection,
    nome,
    cognome,
    codiceFiscale,
    dataNascita,
    genesis
  ], () => {
    const sor = {} as SortingModel;
    sor.field = sortBy || "id"
    sor.direction = sortDirection || "DESC"

    return fetchSelectAllPF(paginationModel.page, filters, sor)
  },
    { keepPreviousData: true, onSuccess: (getResponse) => { setPfResult(getResponse.data) }, refetchOnWindowFocus: false }
  )

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deletePF(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchPFsQueryKey)
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined) setErrorMessage(e.response.data.message)
      else setErrorMessage("Errore non previsto, riprovare o richiedere assistenza")
    }
  })

  if (query.isError) {
    return (<Navigate to="/" replace />)
  }

  const detailsOperations = (record: GridRenderCellParams<any, PersonaFisicaModel>) => {
    if (record.id !== undefined) {
      let model = (record as any).actions as PersonaFisicaModel
      navigator("/personafisica/".concat(model.id.toString(), "-", model.id_cliente.toString()))
    }
  }

  const deleteOperations = (record: GridRenderCellParams<any, PersonaFisicaModel>) => {
    setSelectedRowElement(record.value)
    toggleDeleteAlert()
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Cliente",
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "cognome",
      headerName: "Cognome",
      flex: 1,
    },
    {
      field: "codice_fiscale",
      headerName: "Codice Fiscale",
      flex: 1,
    },
    {
      field: "data_di_nascita",
      headerName: "Data di Nascita",
      type: "date",
      flex: 1,
    },
    {
      field: "genesi_cliente",
      headerName: "Genesi del Cliente",
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <>
      <FilteredDataTable<PersonaFisicaModel, PersonaFisicaDisplayModel>
        detailsOperations={detailsOperations}
        deleteOperations={deleteOperations}
        rows={
          (pfResult?.data !== undefined) ? pfResult.data.map(d => {
            return {
              id: d.id,
              nome: d.nome,
              cognome: d.cognome,
              codice_fiscale: d.codice_fiscale,
              data_di_nascita: new Date(Date.parse(d.data_di_nascita)),
              genesi_cliente: d.anagraficacliente.genesi_cliente,
              actions: d,
              delete_action: d
            }
          }) : []
        }
        rowCount={pfResult?.count}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        errorMessage={errorMessage}
        hideError={hideError}
        filters={filters}
        sortModel={sortModel}
        onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
        onSortChange={(newSortModel) => {
          newSortModel.forEach(item => {
            if (item !== undefined) {
              setSortBy(item.field)
              setSortDirection(castToBackendSort(item.sort))
            }
          })

          setSortModel(newSortModel)
        }}
      />

      {/* Create */}
      {
        <PersonaFisicaFormComponent
          open={!fabVisible}
          toggleOpen={() => { }}
          onClose={() => alterFabVisibility(true)}
          initialData={{} as PersonaFisicaModel}
        />
      }

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.nome}
        onPositive={
          () => {
            toggleDeleteAlert()
            const x = {} as WithData
            x.data = selectedRowElement?.id
            deleteMutation.mutate(x)
          }
        }
        onNegative={toggleDeleteAlert}
      />
    </>
  )
}

export default PersonaFisicaTableComponent
