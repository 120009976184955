import { PersonaFisicaModel } from "../models/PersonaFisicaModel"

const uniquePFMapFromArrays = (arrays: PersonaFisicaModel[][]) => {
  return arrays.reduce((map: Map<number, PersonaFisicaModel>, arr: PersonaFisicaModel[]) => {
    arr.forEach((value: PersonaFisicaModel) => {
      let existing = map.get(value.id)
      if (existing !== undefined) {
        map.set(value.id, existing)
      }
      map.set(value.id, value)
    })
    return map
  }, new Map<number, PersonaFisicaModel>())
}

export default uniquePFMapFromArrays
