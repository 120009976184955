import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LoginPage from "../pages/auth/LoginPage";
import HomePage from "../pages/home/HomePage";
import PersonaFisicaDetailPage from "../pages/customers/PersonaFisicaDetailPage";
import { QueryClientProvider } from "react-query";
import queryClient from "../utils/QueryClient";
import PersonaGiuridicaDetailPage from "../pages/customers/PersonaGiuridicaDetailPage";
import ContrattoPage from "../pages/contratto/ContrattoPage";
import ContrattoDetailPage from "../pages/contratto/ContrattoDetailPage";
import BenePage from "../pages/beni/BenePage";
import BeneDetailPage from "../pages/beni/BeneDetailPage";
import ClientePage from "../pages/customers/ClientePage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Pages } from "../utils/pages";
import LandingPage from "../pages/website/LandingPage";
import { getAccessToken, getRefreshAccessToken } from "../utils/tokenStore";
import BeneBrandPage from "../pages/beni/BeneBrandPage";
import BeneStatoPage from "../pages/beni/BeneCondizionePage";
import BeneMaterialePage from "../pages/beni/BeneMaterialePage";
import BeneTipologiaPage from "../pages/beni/BeneTipologiaPage";
import BeneColorePage from "../pages/beni/BeneColorePage";
import BeneTipologiaDetailPage from "../pages/beni/BeneTipologiaDetailPage";
import BeneCollezionePage from "../pages/beni/BeneCollezionePage";

const AuthenticationRequired = ({ children }: { children: JSX.Element }) => {
  const token = getAccessToken();
  const refreshToken = getRefreshAccessToken();
  const isAuthenticated = token.length !== 0 && refreshToken.length !== 0;
  return isAuthenticated ? children : <Navigate to={Pages.LOGIN} replace />;
};

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#ffcd38",
    },
    secondary: {
      main: "#000000",
    },
    success: {
      main: "#2e7d32",
    },
    error: {
      main: "#d32f2f",
    },
  },
});

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path={Pages.ROOT} element={<LandingPage />} />
            <Route path={Pages.LOGIN} element={<LoginPage />} />
            <Route
              path={Pages.HOME}
              element={
                <AuthenticationRequired>
                  <HomePage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE}
              element={
                <AuthenticationRequired>
                  <BenePage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_ID}
              element={
                <AuthenticationRequired>
                  <BeneDetailPage />
                </AuthenticationRequired>
              }
            />

            <Route
              path={Pages.BENE_BRAND}
              element={
                <AuthenticationRequired>
                  <BeneBrandPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_COLLEZIONE}
              element={
                <AuthenticationRequired>
                  <BeneCollezionePage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_QUALITA}
              element={
                <AuthenticationRequired>
                  <BeneStatoPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_MATERIALE}
              element={
                <AuthenticationRequired>
                  <BeneMaterialePage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_TIPOLOGIA}
              element={
                <AuthenticationRequired>
                  <BeneTipologiaPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_TIPOLOGIA_ID}
              element={
                <AuthenticationRequired>
                  <BeneTipologiaDetailPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.BENE_COLORE}
              element={
                <AuthenticationRequired>
                  <BeneColorePage />
                </AuthenticationRequired>
              }
            />

            <Route
              path={Pages.PERSONA_FISICA}
              element={
                <AuthenticationRequired>
                  <ClientePage tipo_cliente="PEF" />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.PERSONA_FISICA_ID}
              element={
                <AuthenticationRequired>
                  <PersonaFisicaDetailPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.PERSONA_GIURIDICA}
              element={
                <AuthenticationRequired>
                  <ClientePage tipo_cliente="PG" />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.PERSONA_GIURIDICA_ID}
              element={
                <AuthenticationRequired>
                  <PersonaGiuridicaDetailPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.CONTRATTO}
              element={
                <AuthenticationRequired>
                  <ContrattoPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.CONTRATTO_ID}
              element={
                <AuthenticationRequired>
                  <ContrattoDetailPage />
                </AuthenticationRequired>
              }
            />
            <Route
              path={Pages.ANY}
              element={<Navigate to={Pages.ROOT} replace />}
            />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;
