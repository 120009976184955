import React from "react"
import SideBar from "../../components/sidebar/Sidebar"
import "./HomePage.scss"

const HomePage: React.FC<{}> = () => {

  return (
    <div className="home">
      <SideBar />
      <div className="homeContainer">
        Da Personalizzare
      </div>
    </div>
  )
}

export default HomePage
