import React, { useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { GridColDef, GridFilterModel, GridRenderCellParams, GridSortModel } from "@mui/x-data-grid"
import { FetchPaginatedApiModel, WithData, WithFabObj } from "../../../models/CommonModels"
import { SortingModel } from "../../../models/shared/SortingModel"
import { castToBackendSort } from "../../../utils/sorting"
import DeleteDialog from "../../shared/DeleteDialog"
import FilteredDataTable from "../../shared/FilteredDataTable"
import BeneCondizioneFormComponent from "./BeneCondizioneFormComponent"
import { BeneCondizioneModel } from "../../../models/api/response/bene/BeneCondizioneModel"
import { deleteCondizione, getCondizione } from "../../../api/bene/condizione"
import { fetchQualitaBeneSelectQueryKey } from "../../../utils/QueryClient"

interface BeneCondizioneTableComponentProps extends WithFabObj {
  enableCreate: boolean
}

const BeneCondizioneTableComponent: React.FC<BeneCondizioneTableComponentProps> = ({ fabVisible, alterFabVisibility, enableCreate }) => {

  const queryClient = useQueryClient()

  const [editFormOpen, setEditFormOpen] = useState<boolean>(false)
  const toggleEditFormOpen = () => setEditFormOpen(!editFormOpen)

  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false)
  const toggleDeleteAlert = () => setDeleteAlertOpen(!deleteAlertOpen)

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 5,
  })
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("")

  const [conditions, setConditions] = useState<FetchPaginatedApiModel<BeneCondizioneModel[]>>(undefined as any)
  const [selectedRowElement, setSelectedRowElement] = useState<BeneCondizioneModel>()

  const [filters, setFilters] = useState<GridFilterModel | undefined>()
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const [errorMessage, setErrorMessage] = useState("")
  const hideError = () => setErrorMessage("")

  // query
  useQuery(
    [fetchQualitaBeneSelectQueryKey, paginationModel.page, filters, sortBy, sortDirection],
    () => {
      const sor = {} as SortingModel
      sor.field = sortBy || "id"
      sor.direction = sortDirection || "DESC"
      return getCondizione(0, paginationModel.page, sor, filters)
    },
    {
      keepPreviousData: true, onSuccess: (getResponse) => {
        setConditions(getResponse.data)
      }, refetchOnWindowFocus: false
    })

  const deleteMutation = useMutation({
    mutationFn: (x: WithData) => deleteCondizione(x.data),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchQualitaBeneSelectQueryKey)
    },
    onError: (e: any) => {
      if (e.response.data.message !== undefined) setErrorMessage(e.response.data.message)
      else setErrorMessage("Errore non previsto, riprovare o richiedere assistenza")
    }
  })

  const editOperations = (record: GridRenderCellParams<any, BeneCondizioneModel>) => {
    setSelectedRowElement(record.value)
    toggleEditFormOpen()
  }

  const deleteOperations = (record: GridRenderCellParams<any, BeneCondizioneModel>) => {
    setSelectedRowElement(record.value)
    toggleDeleteAlert()
  }

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "ID Condizione Bene",
      flex: 1,
    },
    {
      field: "nome",
      headerName: "Nome",
      flex: 1,
    },
    {
      field: "descrizione",
      headerName: "Descrizione",
      sortable: false,
      flex: 1,
    },
  ];

  return (
    <>
      <FilteredDataTable<BeneCondizioneModel, {}>
        editOperations={editOperations}
        deleteOperations={deleteOperations}
        rows={
          (conditions?.data !== undefined) ? conditions.data.map(d => {
            return {
              id: d.id,
              nome: d.nome,
              descrizione: d.descrizione,
              actions: d,
              delete_action: d
            }
          }) : []
        }
        rowCount={conditions?.count}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        errorMessage={errorMessage}
        hideError={hideError}
        filters={filters}
        sortModel={sortModel}
        onFilterChange={(newFilterModel) => setFilters(newFilterModel)}
        onSortChange={(newSortModel) => {
          newSortModel.forEach(item => {
            if (item !== undefined) {
              setSortBy(item.field)
              setSortDirection(castToBackendSort(item.sort))
            }
          })

          setSortModel(newSortModel)
        }}
      />

      {/* Create */}
      {
        <BeneCondizioneFormComponent
          initialData={{} as BeneCondizioneModel}
          open={enableCreate ? !fabVisible : false}
          toggleOpen={() => { }}
          onClose={() => alterFabVisibility(true)}
        />
      }

      {/* Edit */}
      {
        (selectedRowElement !== undefined) && <BeneCondizioneFormComponent
          initialData={selectedRowElement}
          open={editFormOpen}
          toggleOpen={() => {
            toggleEditFormOpen()
          }}
          onClose={() => {
            setSelectedRowElement(undefined)
            toggleEditFormOpen()
          }}
          editEnabled={true}
        />
      }

      {/* Delete */}
      <DeleteDialog
        open={deleteAlertOpen}
        onClose={toggleDeleteAlert}
        identifier={selectedRowElement?.id}
        onPositive={
          () => {
            toggleDeleteAlert()
            const x = {} as WithData
            x.data = selectedRowElement?.id
            deleteMutation.mutate(x)
          }
        }
        onNegative={toggleDeleteAlert}
      />
    </>
  )
}

export default BeneCondizioneTableComponent
