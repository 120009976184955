import React from "react"
import Box from "@mui/material/Box"
import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import Collapse from "@mui/material/Collapse"
import CloseIcon from "@mui/icons-material/Close"

interface ErrorMessageAlertParameters {
  visible: boolean,
  hide: () => void,
  error_message: string
}

const ErrorMessageAlertComponent: React.FC<ErrorMessageAlertParameters> = ({ visible, hide, error_message }) => {

  return (
    <Box sx={{ width: "100%" }}>
      <Collapse in={visible}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => hide()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {error_message}
        </Alert>
      </Collapse>
    </Box>
  );
}

export default ErrorMessageAlertComponent
