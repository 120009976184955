import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material"
import { BeneModelDetailed } from "../../models/BeneModel"
import { WithCUToggleCommandsAndData, WithRecordID } from "../../models/CommonModels"
import { useState } from "react"
import { BeneContrattoPutModel, BeneContrattoRequest, GrafoRaggiungibilita } from "../../models/BeneContrattoModel"
import { BeneContrattoPortaleCreazioneInGestioneStatoModel, BeneContrattoPortalePostModel } from "../../models/BeneContrattoPortaleModel"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { editBeneContratto, fetchGrafoRaggiungibilita } from "../../api/benecontratto"
import { fetchDetailedBeneQuery, fetchPossibleContrattoBeneAssociationQueryKey } from "../../utils/QueryClient"
import GrafoContrattoSelectComponent from "../graforaggiungibilita/GrafoContrattoSelectComponent"
import GrafoCondizioneSelectComponent from "../graforaggiungibilita/GrafoCondizioneSelectComponent"
import ErrorMessageAlertComponent from "../errors/ErrorMessageAlertComponent"
import { VenditaBeneContrattoPostModel } from "../../models/VenditaBeneContrattoModel"
import { REQUIRED_HELPER_TEXT } from "../../utils/form"
import CredenzialiPortaleVenditaSelectTableRowComponent from "../credenzialiportalevendita/CredenzialiPortaleVenditaSelectTableRowComponent"
import { AxiosError } from "axios"
import { fromAxiosErrorToMessage } from "../../utils/crud"

const BeneCondizioneFormComponent: React.FC<WithCUToggleCommandsAndData<BeneModelDetailed>> = ({ initialData, open, toggleOpen, editEnabled }) => {

  const queryClient = useQueryClient()

  const [hideError, setHideError] = useState<boolean>(true)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const [grafoRaggiungibilita, setGrafoRaggiungibilita] = useState<GrafoRaggiungibilita>(undefined as any)
  const [contrattoTarget, setContrattoTarget] = useState(0)
  const [condizioneTarget, setCondizioneTarget] = useState(-1)

  const [beneContrattoPortalePostRecords] = useState<BeneContrattoPortaleCreazioneInGestioneStatoModel[]>([])

  const [credenzialiPortaleVenditaId, setCredenzialiPortaleVenditaId] = useState(beneContrattoPortalePostRecords[0]?.credenzialiportalevendita.id)

  const [valutazione, setValutazione] = useState(initialData.valutazione_massima.toString())

  const credenzialiutilizzabili = (() => {
    if (grafoRaggiungibilita === undefined || beneContrattoPortalePostRecords === undefined) {
      return []
    }
    const credenzialiTmpList = []
    for (const credenziale of grafoRaggiungibilita?.credenzialiportalevendita) {
      let utilizzata = beneContrattoPortalePostRecords.filter(x => x.credenzialiportalevendita.id === credenziale.id)
      if (utilizzata.length <= 0) credenzialiTmpList.push(credenziale)
    }
    return credenzialiTmpList
  })()

  const [trattenuteOC, setTrattenuteOC] = useState((initialData.benecontratto !== undefined && initialData.benecontratto[0] !== undefined) ? initialData.benecontratto[0]?.contratto?.importo.toString() || "" : '')
  const [totaleVendita, setTotaleVendita] = useState("")

  const grafoRaggiungibilitaQuery = useQuery({
    queryKey: [fetchPossibleContrattoBeneAssociationQueryKey, initialData.benecontratto[0].id],
    queryFn: () => fetchGrafoRaggiungibilita(initialData.id),
    onSuccess: (getResponse) => {
      if (getResponse.data.condizioniraggiunbili.length > 0) setCondizioneTarget(getResponse.data.condizioniraggiunbili[0].id)
      else {
        getResponse.data.condizioniraggiunbili.unshift({ id: 0, nome: "Non modificabile", aggiunta_contratto: false, aggiunta_portale: false, aggiunta_vendita: 0 })
        setCondizioneTarget(-1)
      }
      if (getResponse.data.contratti.length > 0) {
        setContrattoTarget(getResponse.data.contratti[0].id)
      } else {
        getResponse.data.contratti.unshift({
          id: 0,
          titolo: "Non disponibile",
          id_cliente: initialData.anagraficacliente.id,
          id_tipologia_contratto: -1,
          id_stato_contratto: -1,
          quantita: 0,
          data_scadenza: "...",
          importo: 0
        })
      }
      setGrafoRaggiungibilita(getResponse.data)
      if (getResponse.data.credenzialiportalevendita !== undefined && getResponse.data.credenzialiportalevendita.length > 0) {
        // first one by default if present
        setCredenzialiPortaleVenditaId(getResponse.data.credenzialiportalevendita[0].id)
      }
    }, refetchOnWindowFocus: false,
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const editMutation = useMutation({
    mutationFn: (x: WithRecordID) => editBeneContratto(x),
    onSuccess: () => {
      queryClient.invalidateQueries(fetchDetailedBeneQuery)
    },
    onError: (err: AxiosError) => {
      setHideError(false)
      setErrorMessage(fromAxiosErrorToMessage(err))
    }
  })

  const closeActions = () => {
    toggleOpen()
    if (!hideError) {
      queryClient.invalidateQueries(fetchDetailedBeneQuery)
    }
  }

  const condizioneSelezionataAmmettePortale = () => {
    if (grafoRaggiungibilitaQuery.isSuccess) {
      for (let condizione of grafoRaggiungibilita.condizioniraggiunbili) if (condizione.id === condizioneTarget)
        return !!condizione.aggiunta_portale
    }
    return false
  }

  const condizioneSelezionataRichiedeVendita = () => {
    if (grafoRaggiungibilitaQuery.isSuccess) {
      for (let condizione of grafoRaggiungibilita.condizioniraggiunbili) if (condizione.id === condizioneTarget)
        return condizione.aggiunta_vendita > 0
    }
    return false
  }

  const isContrattoRequired = () => {
    if (grafoRaggiungibilitaQuery.isSuccess) {
      for (let record of grafoRaggiungibilita.condizioniraggiunbili) {
        if (record.id === condizioneTarget) return record.aggiunta_contratto
      }
    }
    return false
  }

  if (grafoRaggiungibilitaQuery.isFetching || grafoRaggiungibilitaQuery.isLoading || grafoRaggiungibilitaQuery.isRefetching)
    return (
      <LinearProgress />
    )

  const handleSubmit = () => {
    if (isNaN(Number(trattenuteOC.replace(",", "."))) || isNaN(Number(totaleVendita.replace(",", ".")))) {
      setErrorMessage("Controlla il formato dei parametri")
      return
    }
    let id_contratto = initialData.benecontratto[0].contratto ? initialData.benecontratto[0].contratto.id
      : !isContrattoRequired() ? undefined : contrattoTarget !== 0 ? contrattoTarget : undefined
    let putData = {} as BeneContrattoPutModel
    putData.id_condizione = condizioneTarget
    putData.id_contratto = id_contratto

    if (condizioneSelezionataRichiedeVendita()) {
      let venditabenecontratto = {} as VenditaBeneContrattoPostModel
      venditabenecontratto.percentuale_trattenute_oc = Number(trattenuteOC.replace(",", "."))
      venditabenecontratto.totale_lordo = Number(totaleVendita.replace(",", "."))
      putData.venditabenecontratto = venditabenecontratto
    } else {
      putData.venditabenecontratto = undefined
    }

    if (condizioneSelezionataAmmettePortale()) {
      let benecontrattoportale = {} as BeneContrattoPortalePostModel
      benecontrattoportale.valutazione = Number(valutazione)
      benecontrattoportale.id_credenziali_portale_vendita = credenzialiPortaleVenditaId
      putData.benecontrattoportale = [benecontrattoportale]
    } else {
      putData.benecontrattoportale = undefined
    }

    // TODO: verify
    // putData.benecontrattoportale = beneContrattoPortalePostRecords.length > 0 && !!condizioneSelezionataAmmettePortale() ?
    //   beneContrattoPortalePostRecords.map(x => x.benecontrattoportale) : undefined

    let putRequest = {} as WithRecordID
    let putModel = {} as BeneContrattoRequest
    putModel.benecontratto = putData

    putRequest.data = putModel
    putRequest.recordID = initialData.benecontratto[0].id

    editMutation.mutate(putRequest)
  }

  return (<>
    <Dialog
      open={open}
      onClose={closeActions}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
        }
      }}
    >
      <DialogTitle>Modifica Condizioni Bene</DialogTitle>
      <DialogContent style={{ width: 500 }}>

        <ErrorMessageAlertComponent
          error_message={errorMessage}
          hide={() => { setHideError(true) }}
          visible={!hideError}
        />

        <Typography sx={{ fontSize: '12px', color: 'grey' }}>{REQUIRED_HELPER_TEXT}</Typography>
        <br />
        {
          (
            <Box display="flex" flexDirection="column" justifyContent="center">
              {
                initialData.benecontratto[0].contratto ? (
                  <GrafoContrattoSelectComponent
                    records={[initialData.benecontratto[0].contratto]}
                    disabled={true}
                    setId={setContrattoTarget}
                    initialId={initialData.benecontratto[0].contratto.id}
                    label="Seleziona Contratto"
                  />
                ) : (
                  <GrafoContrattoSelectComponent
                    records={grafoRaggiungibilita.contratti}
                    disabled={contrattoTarget === 0}
                    setId={setContrattoTarget}
                    initialId={contrattoTarget}
                    label="Seleziona Contratto"
                  />
                )
              }
              <br />
              <GrafoCondizioneSelectComponent
                records={grafoRaggiungibilita.condizioniraggiunbili}
                disabled={condizioneTarget === -1}
                setId={setCondizioneTarget}
                initialId={condizioneTarget}
                label="Seleziona Condizione"
              />

              {
                condizioneSelezionataAmmettePortale() && (
                  credenzialiutilizzabili.length <= 0
                    ? <Typography><br />Nessun portale di vendita associato al cliente, è possibile inserirlo successivamente</Typography>
                    : <>
                      <TextField
                        InputProps={{
                          endAdornment: <InputAdornment position="end">€</InputAdornment>,
                          inputProps: { min: 1 }
                        }}
                        //error={error ? error : undefined}
                        margin="normal"
                        type="number"
                        fullWidth
                        required
                        label="Valutazione"
                        name="valutazione"
                        size="small"
                        value={valutazione}
                        onChange={e => {
                          //setError(false)
                          setValutazione(e.target.value)
                        }}
                      />
                      <br />
                      <CredenzialiPortaleVenditaSelectTableRowComponent
                        setSelected={setCredenzialiPortaleVenditaId}
                        records={grafoRaggiungibilita?.credenzialiportalevendita}
                      />
                      <br />
                    </>
                )
              }
              {
                condizioneSelezionataRichiedeVendita() && (
                  <>
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }}
                      margin="normal"
                      required
                      type="number"
                      label="Trattenute %"
                      name="trattenute_in_perc"
                      size="small"
                      value={trattenuteOC}
                      onChange={e => {
                        setTrattenuteOC(e.target.value)
                      }}
                    />
                    <TextField
                      InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                      }}
                      margin="normal"
                      required
                      type="number"
                      label="Totale Vendita"
                      name="totale_vendita"
                      size="small"
                      value={totaleVendita}
                      onChange={e => {
                        setTotaleVendita(e.target.value)
                      }}
                    />
                  </>
                )
              }
            </Box>
          )
        }
      </DialogContent>
      <DialogActions sx={{ paddingLeft: '18pt', paddingRight: '18pt', justifyContent: 'space-between' }}>
        <Box sx={{ justifyContent: "flex-start" }}>
          <Button onClick={() => closeActions()}>Chiudi</Button>
        </Box>
        <Box sx={{ justifyContent: "flex-end" }}>
          <Button onClick={() => handleSubmit()} variant="contained">Applica</Button>
        </Box>
      </DialogActions>
    </Dialog >
  </>)
}

export default BeneCondizioneFormComponent
