
import { PersonaGiuridicaModel } from "../models/PersonaGiuridicaModel"
import { api_url } from "../utils/ApiContext"
import { FetchPaginatedApiModel, WithRecordID, WithData } from "../models/CommonModels"
import { deleteRequest, getRequest, postRequest, putRequest } from "./apiCommon"

const fetchSelectAllPG = (page: number, ragione_sociale: string, partita_iva: string) => {
  let uri = api_url.concat("/api/personagiuridica?")

  ragione_sociale = ragione_sociale.replaceAll("?", "")
  partita_iva = partita_iva.replaceAll("?", "")

  if (ragione_sociale.length > 0)
    uri = uri.concat("ragione_sociale=", ragione_sociale)

  if (partita_iva.length > 0 && uri.endsWith("?"))
    uri = uri.concat("partita_iva=", partita_iva)
  else if (partita_iva.length > 0 && !uri.endsWith("?"))
    uri = uri.concat("&partita_iva=", partita_iva)

  if (uri.endsWith("?")) return fetchAllPG(page, uri.concat("skip="))

  return fetchAllPG(page, uri.concat("&skip="))
}

const fetchAllPG = (page: number, uri: string = api_url.concat("/api/personagiuridica?skip=")) => {
  let skip = 0
  let take = "15"
  if (page > 1) skip = (page - 1) * 15
  return getRequest<FetchPaginatedApiModel<PersonaGiuridicaModel[]>>
    (uri.concat(skip.toString(), "&take=", take.toString()))
}

const createPG = (postdata: WithData) => {
  return postRequest(api_url.concat("/api/personagiuridica"),
    postdata.data)
}

const FetchPG = (getData: WithData) => {
  return getRequest<PersonaGiuridicaModel>
    (api_url.concat("/api/personagiuridica/", getData.data as string))
}

const deletePG = (postdata: WithData) => {
  return deleteRequest(api_url.concat("/api/personagiuridica/", postdata.data as string))
}

const editPG = (putdata: WithRecordID) => {
  return putRequest(api_url.concat("/api/personagiuridica/", putdata.recordID.toString()),
    putdata.data)
}

export { fetchAllPG, createPG, FetchPG, deletePG, editPG, fetchSelectAllPG }
